function invoiceRefundWithNewPrices(UserInfo, filtersUtils, Alert, $filter) {
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: require('../../templates/refund-new-prices-button-detail.html'),
        controller: function ($scope, $routeParams, Task) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagConfirmNewPrices = false;

            $scope.canAddInvoice = function() {
                return UserInfo.hasPerm('invoices.add_invoice')
            }

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                let json = {
                    ids: ids
                };
                let task = new Task();
                task.type = 'IRCP';
                task.params = json;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.BULK_REFUND_INVOICES_NEW_PRICES_SUCCESS'), true);
                    $scope.backgroundTasks.push(data.data.id);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('INVOICES.BULK_REFUND_INVOICES_NEW_PRICES_ERROR'), true);
                })
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.confirmSingleRefundNewPrices = function () {
                $scope.flagConfirmNewPrices = true;
            };

            $scope.clickNewSinglePricesNo = function () {
                $scope.flagConfirmNewPrices = false;
            };

            $scope.clickNewSinglePricesYes = function () {
                performRequest([$routeParams.id]);
            };
        }
    }
}

export { invoiceRefundWithNewPrices }
