function toggleSwitch() {
    return {
        restrict: 'AE',
        scope: {
            value: '=ngModel',
            disabled: '=ngDisabled'
        },
        template: `
            <div class="btn-group btn-group-justified">
                <div class="btn-group btn-toggle">
                    <button class="btn btn-sm"
                            ng-class="{'active': value, 'btn-default': !value}"
                            value="true" ng-click="value = true">
                        <span class="col-xs-12">{{ "INVOICES.AGREEMENT_PARTIAL_SEARCH" | translate }}</span>
                    </button>
                </div>
                <div class="btn-group btn-toggle">
                    <button class="btn btn-sm"
                            ng-class="{'active': !value, 'btn-default': value}"
                            value="false" ng-click="value = false">
                        <span class="col-xs-12">{{ "INVOICES.AGREEMENT_TOTAL_SEARCH" | translate }}</span>
                    </button>
                </div>
            </div>
        `,
        controller: function ($scope) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            
        }
    }
}

export {toggleSwitch}
