{
  "BANKS": {
    "PORTFOLIO": "Cartera",
    "REMITTANCES": "Remesas",
    "REMITTANCE": "Remesa",
    "BANK": "Banco",
    "ANY": "Cualquier Banco",
    "ANY_RULE": "Cualquier norma",
    "FROM": "Desde",
    "TO": "Hasta",
    "TOTAL": "Total",
    "NEW_REMITTANCE": "Nueva remesa",
    "GENERATE": "Generar",
    "INVOICES_TO_REMIT": "Facturas para remitir",
    "REMITTING": "Remesando",
    "DOWNLOAD": "Descargar",
    "SELECT_BANK": "Selecciona Banco",
    "EXTRA_PAYMENT": "Gastos extra",
    "RECEIPTS": "Recibos",
    "PAID": "Pagado",
    "RETURNED": "Devuelto",
    "REJECTED": "Rechazado",
    "SEARCH_RECEIPT": "Buscar...",
    "RECEIPT_NUMBER": "Nº Recibo",
    "NO_INVOICE": "Nº Factura",
    "NO_REMITTANCE": "Nº Remesa",
    "ALL": "Todos",
    "STATUS": "Estado",
    "CREATION_DATE": "Fecha creación",
    "UNPAID_DATE": "Fecha impago",
    "ANY_STATUS": "Cualquiera",
    "DATE_RANGE": "Fechas",
    "WITHOUT_FILTER": "No Filtrar",
    "DATE_FROM": "Desde",
    "DATE_TO": "Hasta",
    "DELETE": "Borrar",
    "YES": "Si",
    "NO": "No",
    "DELETE_RECEIPT": "¿Eliminar Recibo?",
    "XML_SEPA": "N19 SEPA",
    "XML_SEPA_FIRST": "N19 SEPA (FIRST)",
    "XML_SEPA_RECURRENT": "N19 SEPA (REC)",
    "XML_SEPA_FSDD": "N58 SEPA",
    "XML_SEPA_FSDD_FIRST": "N58 SEPA (FIRST)",
    "XML_SEPA_FSDD_RECURRENT": "N58 SEPA (REC)",
    "VIEW": "Ver",
    "REMITTANCE_DATA": "Datos de la Remesa",
    "REMITTANCE_DATE": "Fecha de la remesa",
    "COMPANY_NAME": "Nombre de la empresa",
    "COMPANY_ID": "Id de la Empresa",
    "COMPANY_ADDRESS": "Dirección de la compañía",
    "COMPANY_COUNTRY": "País",
    "VALUE_DATE": "Fecha valor",
    "CREDIT_LIMIT": "Límite de crédito",
    "SUFFIX": "Sufijo SEPA",
    "DATE": "Fecha",
    "REMITTANCE_RECEIPTS": "Recibos de la Remesa",
    "DOWNLOAD_RECEIPTS": "Descargar",
    "DELETE_REMITTANCE": "Borrar remesa",
    "ACTIONS": "Acciones",
    "DELETEQ": "¿Eliminar remesa?",
    "DELETED_REMITTANCE_OK": "Remesa eliminada correctamente.",
    "RETURN": "Devolver",
    "RETURN_RECEIPT": "¿Devolver recibo?",
    "PAY_RECEIPT": "¿Pagar recibo?",
    "ACCOUNT": "Cuenta",
    "N58_TEXT": "N58 (texto)",
    "BOOK34": "N34",
    "MINIMUM": "Mínimo",
    "MAXIMUM": "Máximo",
    "REASON": "Motivo",
    "AC01": "Número de cuenta incorrecto (IBAN no válido)",
    "AC04": "Cuenta cancelada",
    "AC06": "Cuenta bloqueada y/o cuenta bloqueada por el deudor para adeudos directos",
    "AC13": "Cuenta del deudor es cuenta de un consumidor",
    "AG01": "Cuenta no admite adeudos directos",
    "AG02": "Código de operación incorrecto",
    "AM04": "Saldo insuficiente",
    "AM05": "Operación duplicada",
    "BE01": "Titular de la cuenta de cargo no coincide con el deudor",
    "BE05": "Identificador del acreedor incorrecto",
    "CNOR": "La entidad del beneficiario no está registrada",
    "DNOR": "La entidad del ordenante no está registrada",
    "FF01": "Formato no válido",
    "MD01": "Mandato no válido o inexistente",
    "MD02": "Faltan datos del mandato o son incorrectos",
    "MD07": "Deudor fallecido",
    "MS02": "Razón no especificada por el cliente (orden del deudor)",
    "MS03": "Razón no especificada p_(or la entidad del deudor",
    "RC01": "Identificador de la entidad incorrecto (BIC no válido)",
    "RR01": "Falta identificación o cuenta del deudor Razones regulatorias",
    "RR02": "Falta nombre o dirección del deudor Razones regulatorias",
    "RR03": "Falta nombre o dirección del acreedor Razones regulatorias",
    "RR04": "Razones regulatorias",
    "SL01": "Servicios específicos ofrecidos por la entidad del deudor",
    "CREDIT_RETURNED_CUSTOMER": "Abono devuelto por el cliente",
    "REFUND": "Abono",
    "DELETING_REMITTANCE": "Eliminando remesa",
    "IT_EXISTS": "Existe",
    "ANOTHER_INVOICE": "otra factura",
    "WITH_SAME_IDN": "con el mismo IDN y debería ser cambiada.",
    "IDN": "IDN",
    "INVOICE": "Factura",
    "INV": "Fra",
    "SAVE": "Guardar",
    "PAY": "Pagar",
    "UPDATED_REMITTANCE_OK": "Remesa actualizada correctamente.",
    "SECONDARY_SEPA": "ID sepa secundario",
    "GENERATE_REMIT": "Generar remesa",
    "INVOICES": "Facturas",
    "NEW_REMITTANCE_DEFERMENTS": "Nueva remesa de recibos",
    "NO_RECEIPT": "Nº Receipt",
    "REMIT_DATE": "Fecha de remesa",
    "UPDATE_EXTRA_EXPENSES_OK": "Gastos extra actualizados correctamente.",
    "UPDATE_EXTRA_EXPENSES_KO": "Error al actualizar gastos extra.",
    "DEFERMENT": "Aplazamiento",
    "ENTITY": "Entidad",
    "BANK_UPDATED": "Datos de banco actualizados correctamente.",
    "BANK_UPDATED_ERROR": "Error actualizando los datos del banco.",
    "NEW_BANK": "Nuevo banco",
    "CREATE_BANK": "Banco creado correctamente.",
    "CREATE_BANK_ERROR": "Error al crear el banco.",
    "DELETE_BANK": "¿Eliminar banco?",
    "DELETE_BANK_OK": "Banco eliminado correctamente.",
    "DELETE_BANK_ERROR": "Error al eliminar el banco.",
    "CREATING_REMITTANCE": "Remesa en proceso de creación",
    "MANUAL": "Manual",
    "NORMAL": "Normal",
    "REFUND_REMIT": "Devolución",
    "REFUND_B2B_REMIT": "Devolución B2B",
    "REMITTANCE_TYPE": "Tipo de remesa",
    "SELECT_TYPE": "Seleccionar tipo",
    "TYPE": "Tipo",
    "Date range": "Fechas",
    "Date from": "Desde",
    "Date to": "Hasta",
    "Without Filter": "No filtrar",
    "Creation date range": "Fecha de creación",
    "Remit date": "Fecha de remesa",
    "Register date": "Fecha de registro",
    "LOCK": "Bloquear",
    "UNLOCK": "Desbloquear",
    "REMITTANCE_LOCKED": "Remesas bloquedas correctamente",
    "REMITTANCE_UNLOCKED": "Remesas desbloquedas correctamente",
    "RULE": "Norma",
    "XML_SEPA_CORE": "N19 SEPA CORE",
    "XML_SEPA_FSDD_CORE": "N58 SEPA CORE",
    "XML_SEPA_FSDD_FIRST_CORE": "N58 SEPA CORE (FIRST)",
    "XML_SEPA_FSDD_RECURRENT_CORE": "N58 SEPA CORE (REC)",
    "FIRST": "FIRST",
    "RCUR": "RCUR",
    "FIRST_RECEIPT": "Es primer recibo",
    "FACTORING": "Factoring",
    "PAYMENT_DEADLINE": "Días para vencimiento",
    "NOT_DEBITED": "No domiciliados",
    "XML_SEPA_CORE_REFACTOR": "N19 SEPA CORE REFACTURA",
    "BOOK34_REFACTOR": "N34 REFACTURA",
    "DEBIT_METHOD": "Método remesa",
    "B2B_REMITTANCE": "Remesa B2B",
    "XML_SEPA_B2B": "N19 SEPA B2B",
    "XML_SEPA_B2B_REFACTOR": "N19 SEPA B2B REFACTURA",
    "XML_SEPA_B2B_IBERCAJA": "N19 SEPA B2B IBERCAJA",
    "Bulk download CSV": "Descarga de CSV en cola",
    "Bulk download generation Task error": "Se ha producido un error en la generación de la tarea de descarga",
    "MAN1": "Cliente o cuenta inexistente",
    "SEPA": "SEPA",
    "SELECT_RECEIPTS": "SELECCIONAR",
    "GENERATE_BY_AMOUNT": "Seleccionar por Importe",
    "GENERATE_BY_AMOUNT_HELP_TEXT": "Indique de que cantidad desea y se seleccionarán automáticamete las facturas",
    "RECONCILED": "Conciliada",
    "RECOVER": "Recobro",
    "ANY_TYPE":"Cualquiera",
    "XML_SEPA_FIRST_CORE": "N19 SEPA CORE(FIRST)",
    "XML_SEPA_RECURRENT_CORE": "N19 SEPA CORE(REC)",
    "IS_FOREIGN_ACCOUNT": "Es cuenta extranjera",
    "XML_SEPA_B2B_FIRST": "N19 SEPA B2B (FIRST)",
    "XML_SEPA_B2B_RCUR": "N19 SEPA B2B (RCUR)",
    "FAST_REMITTANCE": "Remesa rápida",
    "GROUP_REMITTANCE": "Remesa Agrupada",
    "XML_SEPA_GROUPED_CORE": "N19 Agrupada SEPA"
  }
}
