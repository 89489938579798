const InvoiceSeriesDetailCtrl = ['$scope', '$filter', 'InvoiceSeries', 'UserInfo', 'Alert',
    function ($scope, $filter, InvoiceSeries, UserInfo, Alert) {
        var $translate = $filter('translate');

        $scope.invoice_series_list = $scope.results;
        $scope.invoice_series = $scope.result;

        $scope.updateInvoiceSeries = function(invoice_serie) {
            let update_invoice_serie = {
                "id": invoice_serie.id,
                "counter": invoice_serie.counter,
                "finish_date": invoice_serie.finish_date,
                "description": invoice_serie.description
            }

            InvoiceSeries.patch({id: update_invoice_serie.id}, update_invoice_serie, function(data){
                $scope.$emit('refreshList')
                Alert.createAlert('success', $translate('INVOICES.INVOICE_SERIES_UPDATED_SUCCESSFULLY'), true);
            }, function(data){
                Alert.createAlert('danger', $translate('INVOICES.INVOICE_SERIES_UPDATED_FAIL'), true);
            });
        };

        $scope.disableInvoiceSeries = function(invoice_serie) {
            let update_invoice_serie = {
                "id": invoice_serie.id,
                "types_allowed": null
            }

            InvoiceSeries.patch({id: update_invoice_serie.id}, update_invoice_serie, function(data){
                $scope.$emit('refreshList')
                Alert.createAlert('success', $translate('INVOICES.INVOICE_SERIES_UPDATED_SUCCESSFULLY'), true);
            }, function(data){
                Alert.createAlert('danger', $translate('INVOICES.INVOICE_SERIES_UPDATED_FAIL'), true);
            });
        };

        $scope.canEditInvoiceSeries = function () {
            return UserInfo.hasPerm('invoices.change_invoiceseries');
        };

        $scope.canDeleteInvoiceSeries = function () {
            return UserInfo.hasPerm('invoices.delete_invoiceseries');
        };
    }
]

export { InvoiceSeriesDetailCtrl }
