
function invoiceSetPaid(InvoiceSeries, UserInfo, filtersUtils, DateUtils) {
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: require('../../templates/paid-button.html'),
        controller: function ($scope, $http, $rootScope, Alert, $filter) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/invoices/';
            var $translate = $filter('translate');

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagPaid = false;
            $scope.listToUpdate = [];
            $scope.payment = {};
            $scope.payment.payment_date = new Date();
            $scope.type = [
                {code: 'TRF', label: $translate('INVOICES.TRANSFER')},
                {code: 'CMP', label: $translate('INVOICES.COMPENSATION')},
                {code: 'TPV', label: 'TPV'},
                {code: 'FAI', label: $translate('INVOICES.FAILED')}
            ];
            $scope.payment.payment_type = 'TRF';

            $scope.hasPerm = UserInfo.hasPerm('invoices.add_payment_invoice');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {
                    ids: ids,
                    payment_date: DateUtils.transformToRESTyDate($scope.payment.payment_date),
                    payment_type: $scope.payment.payment_type,
                    origin: $scope.payment.origin
                };

                var config = {
                    method: "PUT",
                    url: api,
                    data: json,
                    headers: {"Content-Type": "application/json;charset=utf-8"}
                };
                $http(config)
                    .then(function (data, status, headers, config) {
                        Alert.createAlert('success', $translate('INVOICES.SENT_PAID_OK') + data.data, true);
                        $rootScope.$broadcast('refreshList');
                    }, function (data, status, headers, config) {
                        Alert.createAlert('danger', $translate('INVOICES.SENT_PAID_KO'), true);
                    })
                    /*
                    .finally(function () {
                        $scope.flagPaid = false;
                        $scope.listToUpdate = [];
                        $scope.$parent.loading = false;
                    });
                    */
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.confirmPaid = function () {
                $scope.flagPaid = true;
            };

            $scope.updateNo = function () {
                $scope.flagPaid = false;
            };

            $scope.updateYes = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                performRequest(ids);
            };
        }
    }
}

export { invoiceSetPaid }
