const FCFeesGenerationCtrl = ['$scope', '$filter', 'Task', 'Alert', 'AgreementsMaster', 'BrandsList',
    function ($scope, $filter, Task, Alert, AgreementsMaster, BrandsList) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        $scope.FEES_GENERATION_LIST = require('../../templates/fees-generation-list.html')

        // Get first and last date of previous month
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m - 1, 1);
        var lastDay = new Date(y, m , 0);

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.status = 'WAITING';
        $scope.backgroundTasks = [];
        $scope.reportData = {};
        $scope.empty = true;

        $scope.from_date = firstDay;
        $scope.to_date = lastDay;
        $scope.agreements = null;
        $scope.agreements_selected = null;
        $scope.brand_id = null;
        $scope.error = $translate('FEES.ERROR_OCCURRED');

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        this.getQueryParams = function () {
            if ($scope.from_date > $scope.to_date) {
                var aux = $scope.from_date;
                $scope.from_date = $scope.to_date;
                $scope.to_date = aux;
            }
            var agreements_array = [];
            if ($scope.agreements_selected !== null) {
                for (var obj in $scope.agreements_selected) {
                    agreements_array.push($scope.agreements_selected[obj].code);
                }
            }

            return {
                    'from_date': $scope.from_date,
                    'to_date': $scope.to_date,
                    'agreements': agreements_array,
                    'brand_id': $scope.brand_id
            };
        };

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.generateCommissions = function () {
            if ($scope.from_date > $scope.to_date) {
                $scope.status = 'ERROR';
                $scope.error = $translate('FEES.DATE_GREATER');
                return;
            }
            $scope.status = 'LOADING';

            var task = new Task();
            task.type = 'GFEE';
            task.params = self.getQueryParams();
            this.reloadList();

            task.start().then(function (data, status, headers, config) {
                $scope.status = 'LOADED';
                Alert.createAlert('info', $translate('FEES.MSG') + $translate('FEES.MSG_2'), true);
                $scope.backgroundTasks.push(data.data.id);
            }).catch(function (data, status, headers, config) {
                // TODO: better error control
                $scope.status = 'ERROR';
                Alert.createAlert('danger', $scope.error, true);
            });
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        AgreementsMaster.loadAgreements().then(function (response){
            $scope.agreements = response;
        });

        BrandsList.all().then(function (response) {
            if(response)
                $scope.brands = [{
                    'code': 'null',
                    'name': $translate('BRANDS.NONE')
                }].concat(response)
        });

    }
]

export { FCFeesGenerationCtrl }
