const ProductRateAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/product-rates/';

        var self = this;

        function getProductRateByProductCode(product_code, page_size) {
            var deferred = $q.defer();

            var url_filter = '?product=' + product_code;
            if (page_size !== 0)
                url_filter += '&page_size=' + page_size;

            $http.get(url + url_filter)
                .then(function (product_rates) {
                    deferred.resolve(product_rates.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function getProductRate(id) {
            return _getProductRate(id);
        }

        function _getProductRate(id) {
            var deferred = $q.defer();

            $http.get(url + id)
                .then(function (product_rate) {
                    deferred.resolve(product_rate.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            self.id = id;
            return deferred.promise;
        }

        function createProductRate(data){
            var deferred = $q.defer();

            $http.post(url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function updateProductRate(id, product_rate) {
            var deferred = $q.defer();

            $http.put(url + id, product_rate)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function deleteProductRate(id) {
            var deferred = $q.defer();

            $http.delete(url + id)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function getMetadata(rate_code) {
            var deferred = $q.defer();

            var url_filter = encodeURIComponent(rate_code) + '/metadata/';

            $http.get(url + url_filter)
                .then(function (metadata) {
                    deferred.resolve(metadata.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function updateMetadata(rate_code, metadata) {
            var deferred = $q.defer();

            var url_filter = encodeURIComponent(rate_code) + '/metadata/';

            $http.post(url + url_filter, metadata)
                .then(function (metadata) {
                    deferred.resolve(metadata.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            getProductRateByProductCode: getProductRateByProductCode,
            getProductRate: getProductRate,
            createProductRate: createProductRate,
            updateProductRate: updateProductRate,
            deleteProductRate: deleteProductRate,
            updateMetadata: updateMetadata,
            getMetadata: getMetadata
        };

    }
]

export { ProductRateAPI }
