// Services
import { BrandsList, BrandsListResource } from "./services/BrandsList"
import { brandsSelector } from "./directives/BrandsSelector";

const brands = angular.module('brands', []);

brands 
    .directive("brandsSelector", brandsSelector)
    .factory("BrandsListResource", BrandsListResource)
    .service("BrandsList", BrandsList)

export { brands }
