function invoiceDuplicate(UserInfo, DateUtils) {
    return {
        restrict: 'AE',
        templateUrl: require('../../templates/duplicate-button.html'),
        controller: function ($scope, $http, $rootScope, $routeParams, Alert, $filter, $location) {
            var $translate = $filter('translate');
            $scope.start_date = new Date;
            $scope.finish_date = new Date;

            var api = API_URL + '/api/1.0/invoices/' + $routeParams.id + '/duplicate/';

            $scope.flagConfirmDuplicate = false;
            $scope.canAddInvoice = function() {
                return UserInfo.hasPerm('invoices.add_invoice')
            }

            $scope.confirmDuplication = function () {
                $scope.flagConfirmDuplicate = true;
            }

            $scope.clickNo = function () {
                $scope.flagConfirmDuplicate = false;
            }

            $scope.clickYes = function () {
                let json = {
                    id : $routeParams.id,
                    start_date : DateUtils.transformToRESTyDate($scope.start_date),
                    finish_date : DateUtils.transformToRESTyDate($scope.finish_date)
                }
                let config = {
                    method: "POST",
                    url: api,
                    data: json,
                    headers: {"Content-Type": "application/json;charset=utf-8"},

                };
                $http(config)
                    .then(function (data, status, headers, config) {
                        $location.path('/invoices/' + data.data.id);
                    }, function (data, status, headers, config) {
                        Alert.createAlert('danger', $translate('INVOICES.DUPLICATE_ERROR'), true);
                    })
            }
        }
    }
}

export { invoiceDuplicate }
