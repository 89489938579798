function invoiceDeletion(UserInfo, filtersUtils, Alert, $filter) {
    return {
        restrict: 'AE',
        scope: {},
        template: `
            <li class="nestedLi" ng-show="counter > 0">
                <span class="text-center" ng-click="confirmDeletion();$event.stopPropagation();" >
                    <a href class="nodecorate" style="padding-top: 5px;">{{ "INVOICES.DELETE" | translate }}</a>
                </span>
            </li>

            <modal ng-show="flagConfirm" class="fullscreen-modal" id="delete_invoices_modal" role="dialog" ng-click="$event.stopPropagation();" tabindex="-1" aria-hidden="true">
                <div class="inmodal in">
                    <div class="modal-dialog">
                        <div class="modal-content animated bounceInRight">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal"><span
                                        aria-hidden="true" ng-click="clickNo()">×</span><span class="sr-only">Close</span></button>
                                <i class="fa fa-exclamation-triangle modal-icon"></i>
                                <h4 class="modal-title">{{ "INVOICES.DELETE" | translate }}</h4>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-xs-12 text-center"><label>{{ "INVOICES.DELETEQ" | translate }} {{ counter }} {{ "INVOICES.ELEMENTSQ" | translate }}</label></div>
                                </div>
                                <div class="row">
                                    <p>{{ "PROVIDERS.DISCARD_QUESTION" | translate }}</p>
                                    <div class="btn-group btn-group-justified" ng-init="discard=false">
                                        <div class="btn-group btn-toggle">
                                            <button class="btn btn-sm" name="print_invoices"
                                                    ng-click="$event.stopPropagation();discard=true;"
                                                    ng-class="{'active btn-danger': discard, 'btn-default': !discard}"
                                                    ng-model="discard">
                                                <span class="col-xs-12">{{ "PROVIDERS.YES" | translate }}</span>
                                            </button>
                                        </div>
                                        <div class="btn-group btn-toggle">
                                            <button class="btn btn-sm" name="print_invoices"
                                                    ng-click="$event.stopPropagation();discard=false"
                                                    ng-class="{'active btn-primary': !discard, 'btn-default': discard}"
                                                    ng-model="discard">
                                                <span class="col-xs-12">{{ "CONTRACTS.NO" | translate }}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button ng-click="clickNo()" type="button" class="btn btn-white" data-dismiss="modal">{{ "CANCEL" | translate }}</button>
                                <button ng-click="clickYes()" type="button" class="btn btn-primary" data-dismiss="modal">{{ "INVOICES.CONFIRM" | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </modal>
        `,
        controller: function ($scope, Task) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToDelete = [];

            $scope.hasPerm = UserInfo.hasPerm('invoices.delete_draft');
            $scope.discard = false;

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToDelete = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                let json = {ids: ids, discard: $scope.discard};
                let task = new Task();
                task.type = 'DINV';
                task.params = json;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.BULK_DELETE_INVOICES_SUCCESS'), true);
                    $scope.backgroundTasks.push(data.data.id);
                    $scope.discard = false
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('INVOICES.BULK_DELETE_INVOICES_ERROR'), true);
                    $scope.discard = false
                })

            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
                $scope.discard = false
            };

            $scope.clickYes = function () {
                let ids = filtersUtils.getItemsToDownloadToInt($scope.listToDelete);
                performRequest(ids);
                $scope.flagConfirm = false;
            };
        }
    }
}

export { invoiceDeletion }
