const ProvidersInvoicesCtrl = ['$scope', '$filter', '$window', 'UserInfo', 'Alert', 'Task', 'filtersUtils', 'ProvidersMaster', 'AgreementsMaster', 'BrandsMaster',
    function ($scope, $filter, $window, UserInfo, Alert, Task, filtersUtils, ProvidersMaster, AgreementsMaster, BrandsMaster) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        $scope.PROVIDERS_DOWNLOAD_DROPDOWN = require('../../templates/download-dropdown.html')
        $scope.PROVIDERS_ACTIONS_DROPDOWN = require('../../templates/actions-dropdown.html')

        var initializing = true;

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.itemsToDownload = [];
        $scope.backgroundTasks = [];

        $scope.draggableFields = {
            "pk__in": {
                "label": $translate('POWR.ID')
            },
            "idn__in":{
                "label": $translate('INVOICES.NO_INVOICE'),
                "pattern": "^[a-zA-Z0-9_\\-\\.\/]+$"
            },
            "supply_id__in":{
                "label": $translate('CONTRACTS.SUPPLY_CUPS'),
                "pattern": "^\\w+$"
            },
        };

        $scope.statuses = [
            {code: 'True', label: $translate('PROVIDERS.RECONCILED')},
            {code: 'False', label: $translate('PROVIDERS.NOT_RECONCILED')}
        ];

        $scope.paid_status = [
            {code: "PEN", label: $translate('PROVIDERS.PENDING')},
            {code: "CHE", label: $translate('PROVIDERS.CHECKED')},
            {code: "PAI", label: $translate('PROVIDERS.PAID')},
            {code: "RET", label: $translate('PROVIDERS.RETURNED')}
        ];

        $scope.types = [
            {code: 'N', label: $translate('PROVIDERS.NORMAL')},
            {code: 'A', label: $translate('PROVIDERS.REFUND')},
            {code: 'B', label: $translate('PROVIDERS.REFUND_WITH_REISSUE')},
            {code: 'R', label: $translate('PROVIDERS.REISSUE')},
            {code: 'C', label: $translate('PROVIDERS.TYPE_C')},
            {code: 'G', label: $translate('PROVIDERS.TYPE_G')}
        ];

        $scope.domiciles = [
            {code: 'True', label: $translate('PROVIDERS.DOMICILED')},
            {code: 'False', label: $translate('PROVIDERS.NOT_DOMICILED')}
        ];

        $scope.searchBy = 'name';
        $scope.searchByLabel = $translate('PROVIDERS.NAME');
        $scope.searchFilters = {
            'name': $translate('PROVIDERS.NAME'),
            'address': $translate('PROVIDERS.Address'),
            'remittance_id': $translate('PROVIDERS.REMITTANCE'),
            'distributor': $translate('PROVIDERS.DISTRIBUTOR'),
            'supply': $translate('PROVIDERS.CUPS'),
            'idn': $translate('PROVIDERS.IDN'),
            'search': $translate('PROVIDERS.ALL')
        };

        $scope.filters = {
            search: "",
            name: "",
            address: "",
            balance_min: "",
            balance_max: "",
            reconciled: null,
            state: null,
            provider: null,
            status: null
        };

        $scope.date_filters = {
            date_range: null,
            from_date: null,
            to_date: null
        };

        $scope.filters_by_date = [
            {value: "emission", description: $translate('PROVIDERS.EMISSION_DATE')},
            {value: "period", description: $translate('INVOICES.Billing period date range')},
            {value: "creation", description: $translate('PROVIDERS.CREATION_DATE')},
            {value: "paid", description: $translate('PROVIDERS.PAID_DATE')},
            {value: "expiration", description: $translate('PROVIDERS.EXPIRATION_DATE')}
        ];

        $scope.ordering = {
            "emission_date": $translate('PROVIDERS.EMISSION_DATE'),
            "id": $translate('INVOICES.NO_INVOICE'),
            "creation_date": $translate('PROVIDERS.CREATION_DATE'),
            "start_date": $translate('INVOICES.DATE'),
            "balance": $translate('INVOICES.TOTAL_AMOUNT')
        };

        $scope.date_filters_dict = {};
        $scope.element_date_count = 0;

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
            $scope.counter = selectedCounterChange;
        });

        $scope.$watch('searchQuery', function (newVal, oldVal) {
            $scope.filters[$scope.searchBy] = newVal;
        });

        $scope.$watch('date_filters_dict', function (newVal, oldVal) {
            if (initializing === true) {
                initializing = false;
            }
            else {
                $scope.element_date_count = Object.keys($scope.date_filters_dict).length;
                for (var element in $scope.date_filters_dict) {
                    var sel_option = $scope.date_filters_dict[element].key;
                    if ($scope.date_filters_dict[element].from_date !== null) {
                        $scope.filters[sel_option + "_date_from"] = $scope.date_filters_dict[element].from_date;
                    }
                    else {
                        $scope.filters[sel_option + "_date_from"] = null;
                    }
                    if ($scope.date_filters_dict[element].to_date !== null) {
                        $scope.filters[sel_option + "_date_to"] = $scope.date_filters_dict[element].to_date;
                    }
                    else {
                        $scope.filters[sel_option + "_date_to"] = null;
                    }
                }
            }
        }, true);

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        this.getJSONParams = function (elements) {
            var params = {};
            params.ids = filtersUtils.getItemsToDownloadToInt(elements);
            return params;
        };

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.getStyles = function (result) {
            var styles = '';
            if (result.status == 'PAI')
                styles += ' ' + 'success';
            if (result.status == 'PEN')
                styles += ' ' + 'info';
            if (result.status == 'CHE')
                styles += ' ' + 'warning';
            if (result.status == 'RET')
                styles += ' ' + 'danger';

            return styles;
        };

        $scope.setSearchBy = function (val) {
            $scope.filters[$scope.searchBy] = '';
            $scope.searchBy = val;
            $scope.searchByLabel = $scope.searchFilters[val];
            $scope.filters[val] = $scope.searchQuery;
        };

        $scope.showCreateInvoiceProvider = function () {
            $window.location.href = '/providers/invoices/create/';
        };

        $scope.downloadCSV = function () {
            if ($scope.counter > 0) {
                var task = new Task();
                task.type = 'PCSV';
                task.params = self.getJSONParams($scope.itemsToDownload)
                task.start()
                    .then(function (data, status, headers, config) {
                        Alert.createAlert('success', $translate('PROVIDERS.Bulk download CSV'), true);
                        $scope.backgroundTasks.push(data.data.id);
                    })
                    .catch(function (data, status, headers, config) {
                        Alert.createAlert('danger', $translate('PROVIDERS.Bulk download generation Task error'), true);
                    });
            }
        };

        $scope.applyDatesFilter = function () {
            var element = {
                key: "",
                description: "",
                from_date: null,
                to_date: null
            };
            var sel_option = $scope.date_filters.date_range.value;
            element.description = $scope.date_filters.date_range.description;
            element.key = sel_option;

            if ($scope.date_filters.from_date !== null) {
                element.from_date = $scope.date_filters.from_date;
            }
            else {
                element.from_date = null;
            }
            if ($scope.date_filters.to_date !== null) {
                element.to_date = $scope.date_filters.to_date;
            }
            else {
                element.to_date = null;
            }
            $scope.date_filters_dict[sel_option] = element;
        };

        $scope.deleteDateFilter = function (item, value) {
            // Check for delete from filters
            var substring = item.key;
            for (var element in $scope.filters) {
                if (element.indexOf(substring) !== -1) {
                    delete $scope.filters[element];
                }
            }

            delete $scope.date_filters_dict[item.key];
        };

        $scope.send_to_sii = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                task.type = 'PSII';
                task.params = self.getJSONParams($scope.itemsToDownload);
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.Bulk_Send_to_sii'), true);
                    $scope.backgroundTasks.push(data.data.id);
                    $scope.update_list.comment = '';
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('INVOICES.Bulk_Send_to_sii_error'), true);
                    $scope.update_list.comment = '';
                });

            }
        };

        $scope.canDownloadProviderInvoices = function () {
            return this.canDownloadCSV();
        };

        $scope.canDownloadCSV = function () {
            return UserInfo.hasPerm('providers.download_invoices_csv');
        };

        $scope.canSelectProviderInvoicesForActions = function () {
            return this.can_delete_provider_invoice() ||
                this.can_change_provider_invoice_status() ||
                this.can_change_provider_invoice_provider() ||
                this.can_set_receive_paper_provider_invoice() ||
                this.can_set_paid_date_provider_invoice() ||
                this.can_set_expiration_date_provider_invoice();
        };

        $scope.canCreateProviderInvoice = function () {
            return UserInfo.hasPerm('providers.add_providerinvoice');
        };

        $scope.can_delete_provider_invoice = function () {
            return UserInfo.hasPerm('providers.delete_providerinvoice');
        };

        $scope.can_change_provider_invoice_status = function () {
            return UserInfo.hasPerm('providers.change_provider_invoice_status');
        };

        $scope.can_change_provider_invoice_provider = function () {
            return UserInfo.hasPerm('providers.change_provider_invoice_provider');
        };

        $scope.can_set_receive_paper_provider_invoice = function () {
            return UserInfo.hasPerm('providers.change_set_receive_paper_invoice');
        };

        $scope.can_set_paid_date_provider_invoice = function () {
            return UserInfo.hasPerm('providers.change_set_provider_invoice_paid_date');
        };

        $scope.can_set_expiration_date_provider_invoice = function () {
            return UserInfo.hasPerm('providers.change_set_provider_invoice_expiration_date');
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        ProvidersMaster.loadProviders().then(function (response) {
            $scope.providers = response;
        });

        AgreementsMaster.loadAgreements().then(function (response) {
            $scope.agreements = response;
        });

        BrandsMaster.loadBrands().then(function (response) {
            $scope.brands = response;
        });
    }
]

export { ProvidersInvoicesCtrl }
