{
  "ISSUES": {
    "TITLE": "Issues",
    "TYPE": "Type",
    "CATEGORY": "Category",
    "NO_CONTRACT": "No. Contract",
    "NO_INVOICE": "No. Invoice",
    "SUPPLY_CUPS": "CUPS",
    "ALL": "All",
    "NO_ISSUE": "No. Issue",
    "NO_COMPLAINT": "No. Complaint",
    "STATUS": "Status",
    "CREATION_DATE_RANGE": "Creation date",
    "LAST_MOD_DATE_RANGE": "Last modification date",
    "LAST_STATUS_MOD_DATE_RANGE": "last status modification date",
    "SEARCH": "Search",
    "DATE_RANGE": "Dates",
    "NO_FILTER": "Without filter",
    "DATE_FROM": "From",
    "DATE_TO": "To",
    "VIEW": "View",
    "LOGS":"Logs",
    "SYSTEM": "System",
    "COMMENTS": "Comments",
    "FILES": "Files",
    "ISSUE_DATA": "Issue data",
    "ISSUE_NUMBER": "No. Issue: ",
    "ENABLE_ISSUE_EDITION": "Unlock Edition mode",
    "UPDATE": "Update",
    "CANCEL": "Cancel",
    "ISSUE_INFO": "Issue Info",
    "CREATION_DATE": "Creation date",
    "LAST_MOD_DATE": "Last modification date",
    "LAST_STATUS_MOD_DATE": "Last status modification date",
    "TITLE_INFO": "Title",
    "TYPE_MANDATORY": "Type must be included",
    "CATEGORY_MANDATORY": "Category must be included",
    "OTHER_INFO": "Other datas",
    "ISSUE_UPDATE_SUCCESSFULLY": "Issue update successfully",
    "ANY": "Any",
    "ACTIONS": "Actions",
    "CHANGE_STATUS": "Change status",
    "SELECT_TYPE": "Select Event Issue",
    "SELECT_CATEGORY": "Select Category",
    "SELECT_NEW_STATUS": "Select New Status",
    "DATE_CHANGE_STATUS": "Status Change Date",
    "CHANGE_QUESTION": "Change?",
    "YES": "Yes",
    "NO": "No",
    "ISSUES_STATUS_UPDATED": "No. Issues status updated successfully: ",
    "ERROR_REQUEST": "Error in request.",
    "BACK": "Back",
    "DOWNLOADS": "Download",
    "DOWNLOAD_QUESTION": "Download?",
    "SELECT_DOWNLOAD_ACTION": "Seleccionar acción de descarga",
    "DELETE": "Delete",
    "ELEMENTS": "Elements",
    "DELETED": "Deleted issues: ",
    "REQUEST_CODE": "Request Code:",
    "SUPPLY_DISTRIBUTOR": "Distributor",
    "SELECT_STATUS": "Select Status",
    "CIF": "CIF",
    "SOCIAL_REASON": "Social Reason",
    "PHONE": "Phone",
    "BULK_DOWNLOAD_CSV": "Bulk download XlS on queue",
    "BULK_DOWNLOAD_GENERATION_TASK_ERROR": "Bulk download generation Task Error",
    "DOWNLOAD_CSV": "Download XLS events",
    "DUPLICATE_ISSUE": "Duplicate Issue",
    "DUPLICATE_QUESTION": "Duplicate Issue?",
    "DUPLICATED_ISSUE_SUCCESSFULLY": "Issue Duplicated. New issue ",
    "CHECKED": "Checked",
    "PENDING": "Check pending",
    "CLOSED": "Close",
    "CHANGE_CHECKED": "Change Checked",
    "SELECT_CHECKED_TYPE": "Select Checked Type",
    "STATUS_PENDING": "Pending",
    "STATUS_SENT": "Sent",
    "STATUS_ACCEPT": "Accept",
    "STATUS_REJECT": "Reject",
    "STATUS_ACTIVE": "Active",
    "SELECT_MAXIMETER": "Select maximeter",
    "COMPANY_NAME": "Company"
  }
}