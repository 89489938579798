const InvoiceSeriesListCtrl = ['$scope', '$filter', 'UserInfo',
    function ($scope, $filter, UserInfo) {
        var $translate = $filter('translate');

        $scope.invoiceseries = [];
        $scope.ordering = {
            "-id": $translate('INVOICES.ID')
        };
        $scope.filters = {
            search: "",
            all: "True",
            company: "",
            finish_date_from: "",
            finish_date_to: ""
        };

        $scope.mode = 'list';
        $scope.showCreateForm = function () {
            $scope.mode = 'create';
        };

        $scope.$on('cancelCreate', function (event) {
            $scope.mode = 'list';
        });

        $scope.$on('newInvoiceSeries', function (event) {
            $scope.listReload();
        });

        $scope.canCreateInvoiceSeries = function () {
            return UserInfo.hasPerm('invoices.add_invoiceseries');
        };
    }
]

export { InvoiceSeriesListCtrl }
