function generateFlatRateInvoices(UserInfo, filtersUtils, DateUtils) {
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: require('../../templates/actions-dropdown-generate-flat-rate-invoices.html'),
        controller: function ($scope, $http, $rootScope, Alert, $filter, Task) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var $translate = $filter('translate');

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.listToUpdate = [];

            $scope.hasPerm = true; // UserInfo.hasPerm('invoices.can_create_estimated_invoices');

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            $scope.generateFlatRateInvoices = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                var json = {
                    ids: ids,
                };

                if($scope.flat_rate_date){
                    let offset = $scope.flat_rate_date.getTimezoneOffset()
                    $scope.flat_rate_date = new Date($scope.flat_rate_date.getTime() - (offset*60*1000))

                    json['flat_rate_date'] = $scope.flat_rate_date.toISOString().split('T')[0];
                }

                var task = new Task();
                task.type = 'GFRI';
                task.params = json;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('CONTRACTS.Generate Flat Rate Invoices Task Created'), true);
                }).catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.Generate Flat Rate Invoices Task Creation ERROR'), true);
                }).finally(function () {
                    $scope.flagRemitDate = false;
                    $scope.listToUpdate = [];
                    $scope.$parent.loading = false;
                });

                $scope.flagCloseDate = false;
            };

            $scope.confirmCloseDate = function () {
                $scope.flagCloseDate = true;
            };

            $scope.updateNo = function () {
                $scope.flagCloseDate = false;
            };
        }
    }
}

export {generateFlatRateInvoices}
