const AtrRequestListCtrl = ['$scope', '$filter',
    function ($scope, $filter) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.action = 'detail';
        $scope.atr_requests = [];
        $scope.show_tags = false;

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/

        $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
            $scope.counter = selectedCounterChange;
        });

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.showOrHideTags = function () {
            $scope.show_tags = !$scope.show_tags;
        }

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/

    }
]

export { AtrRequestListCtrl }
