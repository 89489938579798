import angular from "angular";
import ngResource from "angular-resource";

// Controllers
import {CalendarForSupplyCtrl} from './controllers/CalendarForSupplyCtrl.js'
import {DeleteCalendarForSupplyCtrl} from './controllers/DeleteCalendarForSupplyCtrl.js'
import {ElectricityMetersCtrl} from './controllers/ElectricityMetersCtrl.js'
import {ListSuppliesCtrl} from './controllers/ListSuppliesCtrl.js'
import {ReadingsCtrl} from './controllers/ReadingsCtrl.js'
import {SCDoubleReportCtrl} from './controllers/SCDoubleReportCtrl.js'
import {SCImportHistoryCtrl} from './controllers/SCImportHistoryCtrl.js'
import {SCSimpleReportCtrl} from './controllers/SCSimpleReportCtrl.js'
import {SCTabsCtrl} from './controllers/SCTabsCtrl.js'
import {SipsCtrl} from './controllers/SipsCtrl.js'
import {SupplyDetailCtrl} from './controllers/SupplyDetailCtrl.js'
import {AtrRequestSupplyCtrl} from "./controllers/AtrRequestSupplyCtrl";

// Services
import {CalendarForSupplyAPI} from './services/CalendarForSupplyAPI';
import {CNMCParser} from './services/CNMCParser';
import {ConsumptionParser} from './services/ConsumptionParser';
import {ConsumptionReport} from './services/ConsumptionReport';
import {ElectricityMeter} from './services/ElectricityMeter';
import {EonParser} from './services/EonParser';
import {IberdrolaParser} from './services/IberdrolaParser';
import {SipsParser} from './services/SipsParser';
import {SupplyAPI} from './services/SupplyAPI';


// Directives
import {consumptionList} from './directives/consumptionList'
//import {consumptionReading} from './directives/consumptionReading'
import {consumptionReport} from './directives/consumptionReport'
import {doubleReport} from './directives/doubleReport'
import {simpleReport} from './directives/simpleReport'

const supplies = angular.module('supplies', ['pascalprecht.translate', 'notifications', 'tasks']);

supplies.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

supplies.constant('SIPS_FILE_TO_UPLOAD', 'file to upload');
supplies.constant('SIPS_FILE_UPLOADING', 'uploading file');
supplies.constant('SIPS_FILE_UPLOADED', 'file uploaded');
supplies.constant('SIPS_FILE_ERROR', 'file error');
supplies.constant('SIPS_FILE_CANCELED', 'file canceled');
supplies.constant('SIPS_FILE_DELETED', 'file deleted');

supplies.constant('SUPPLY_RATES', [
    // New rates
    { code: 'T20TD', name: '2.0 TD' },
    { code: 'T30TD', name: '3.0 TD' },
    { code: 'T61TD', name: '6.1 TD' },
    { code: 'T62TD', name: '6.2 TD' },
    { code: 'T63TD', name: '6.3 TD' },
    { code: 'T64TD', name: '6.4 TD' },
    { code: 'T30TDVE', name: '3.0 TDVE' },
    { code: 'T61TDVE', name: '6.1 TDVE' },
    // old rates
    { code: 'T20A', name: '2.0 A' } ,
    { code: 'T20DHA', name: '2.0 DHA' } ,
    { code: 'T21A', name: '2.1 A' } ,
    { code: 'T21DHA', name: '2.1 DHA' } ,
    { code: 'T30A', name: '3.0 A' } ,
    { code: 'T31A', name: '3.1 A' } ,
    { code: 'T31DHA', name: '3.1 DHA' } ,
    // { code: 'T61', name: '6.1' } ,
    { code: 'T61', name: '6.1 A' } ,
    { code: 'T61B', name: '6.1 B' } ,
    { code: 'T62', name: '6.2' } ,
    { code: 'T63', name: '6.3' } ,
    { code: 'T64', name: '6.4' } ,
    // { code: 'T65', name: '6.5' } ,
    { code: 'T20DHS', name: '2.0 DHS' },
    { code: 'T21DHS', name: '2.1 DHS' }
]);

supplies.constant('CURRENT_SUPPLY_RATES', [
    { code: 'T20TD', name: '2.0 TD' },
    { code: 'T30TD', name: '3.0 TD' },
    { code: 'T61TD', name: '6.1 TD' },
    { code: 'T62TD', name: '6.2 TD' },
    { code: 'T63TD', name: '6.3 TD' },
    { code: 'T64TD', name: '6.4 TD' },
    { code: 'T30TDVE', name: '3.0 TDVE' },
    { code: 'T61TDVE', name: '6.1 TDVE' }
]);

supplies.constant('SUPPLY_POWER_PERIODS', {
    'T20A': [false, true, false, false, false, false],
    'T20DHA': [true, false, true, false, false, false],
    'T21A': [false, true, false, false, false, false],
    'T21DHA': [true, false, true, false, false, false],
    'T30A': [true, true, true, false, false, false],
    'T31A': [true, true, true, false, false, false],
    'T31DHA': [true, true, true, false, false, false],
    'T61':  [true, true, true, true, true, true],
    'T62':  [true, true, true, true, true, true],
    'T63':  [true, true, true, true, true, true],
    'T64':  [true, true, true, true, true, true],
    'T65':  [true, true, true, true, true, true],
    'T20TD': [true, true, false, false, false, false],
    'T30TD': [true, true, true, true, true, true],
    'T30TDVE': [true, true, true, true, true, true],
    'T61TD': [true, true, true, true, true, true],
    'T61TDVE': [true, true, true, true, true, true],
    'T62TD': [true, true, true, true, true, true],
    'T63TD': [true, true, true, true, true, true],
    'T64TD': [true, true, true, true, true, true]
});

supplies.constant('SUPPLY_ENERGY_PERIODS', {
    'T20A': [false, true, false, false, false, false],
    'T20DHA': [true, false, true, false, false, false],
    'T21A': [false, true, false, false, false, false],
    'T21DHA': [true, false, true, false, false, false],
    'T30A': [true, true, true, false, false, false],
    'T31A': [true, true, true, false, false, false],
    'T31DHA': [true, true, true, false, false, false],
    'T61':  [true, true, true, true, true, true],
    'T62':  [true, true, true, true, true, true],
    'T63':  [true, true, true, true, true, true],
    'T64':  [true, true, true, true, true, true],
    'T65':  [true, true, true, true, true, true],
    'T20TD': [true, true, true, false, false, false],
    'T30TD': [true, true, true, true, true, true],
    'T30TDVE': [true, true, true, true, true, true],
    'T61TD': [true, true, true, true, true, true],
    'T61TDVE': [true, true, true, true, true, true],
    'T62TD': [true, true, true, true, true, true],
    'T63TD': [true, true, true, true, true, true],
    'T64TD': [true, true, true, true, true, true]
});

supplies.constant('SUPPLY_POWER_RANGES', {
    T20A   : [0, 10, 'La tarifa 2.0A debe tener una potencia inferior o igual a 10kW.'],
    T20DHA : [0, 10, 'La tarifa 2.0A debe tener una potencia inferior o igual a 10kW.'],
    T21A   : [10, 15, 'La tarifa 2.1A debe tener una potencia mayor de 10kW y menor o igual a 15kW.'],
    T21DHA : [10, 15, 'La tarifa 2.1DHA debe tener una potencia mayor de 10kW y menor o igual a 15kW.'],
    T30A   : [15, 0, 'La tarifa 3.0A debe tener una potencia mayor a 15kW.'],
    T31A   : [0, 450, 'Las tarifas 3.1A deben tener una potencia inferior a 450 KW.'],
    T31DHA : [0, 450, 'Las tarifas 3.1DHA deben tener una potencia inferior a 450 KW.'],
    T61    : [450, 0, 'Las tarifas 6.1 deben tener una potencia superior a 450 KW.'],
    T62    : [450, 0, 'Las tarifas 6.2 deben tener una potencia superior a 450 KW.'],
    T63    : [450, 0, 'Las tarifas 6.3 deben tener una potencia superior a 450 KW.'],
    T64    : [450, 0, 'Las tarifas 6.4 deben tener una potencia superior a 450 KW.'],
    T20TD  : [0.001, 15.0, 'Las tarifas 2.0 TD deben tener una potencia inferior o igual a 15kW. Mínimo 0,001kW'],
    T30TD  : [15.001, 450.0, 'Las tarifas 3.0 TD deben tener una potencia superior a 15kW en algún periodo y ascendente o constante entre periodos.'],
    T30TDVE: [15.001, 450.0, 'Las tarifas 3.0 TDVE deben tener una potencia superior a 15kW en algún periodo y ascendente o constante entre periodos.'],
    T61TD  : [0.001, 0, 'Las tarifas 6.1 TD deben tener potencia en todos sus periodos y ser ascendente o constante.'],
    T61TDVE: [0.001, 0, 'Las tarifas 6.1 TDVE deben tener potencia en todos sus periodos y ser ascendente o constante.'],
    T62TD  : [0.001, 0, 'Las tarifas 6.2 TD deben tener potencia en todos sus periodos y ser ascendente o constante.'],
    T63TD  : [0.001, 0, 'Las tarifas 6.3 TD deben tener potencia en todos sus periodos y ser ascendente o constante.'],
    T64TD  : [0.001, 0, 'Las tarifas 6.4 TD deben tener potencia en todos sus periodos y ser ascendente o constante.']
});

// Llevado a controlador apra traducir
supplies.constant('SUPPLY_STATUSES', [
    { code: 'ACT', name: 'Activo' } ,
    { code: 'INA', name: 'Inactivo' }
]);

// Tabla de potencias activas normalizadas
supplies.constant('NORMAL_POWERS', [
    0.200,  0.345,  0.598,  1.039,
    0.399,  0.690,  1.195,  2.078,
    0.466,  0.805,  1.394,  2.425,
    0.665,  1.150,  1.992,  3.464,
    0.998,  1.725,  2.988,  5.196,
    1.330,  2.300,  3.984,  6.928,
    1.995,  3.450,  5.976,  10.392,
    2.660,  4.600,  7.967,  13.856,
    3.325,  5.750,  9.959,  17.321,
    3.990,  6.900,  11.951, 20.785,
    4.655,  8.050,  13.943, 24.249,
    5.320,  9.200,  15.935, 27.713,
    5.985,  10.350, 17.927, 31.177,
    6.650,  11.500, 19.919, 34.641,
    8.379,  14.490, 25.097, 43.648
]);

// Fuentes disponibles
supplies.constant('SOURCES', [
    { code: 'SIP', name: 'SIPS' } ,
    { code: 'MAN', name: 'Manual' },
    { code: 'SC', name: 'Concentrador Secundario' },
    { code: 'F101', name: 'F101' }
]);

supplies.constant('ENERGY_ORIGINS', [
    { code: 'DEF', name: 'Por defecto' },
    { code: 'ZER', name: '100% renovable'}
]);

// TIPO DE AUTOCONSUMO
supplies.constant('AUTOCONSUMPTION_TYPE', [
    { code: '00', name: '00: Sin Autoconsumo' },
    { code: '31', name: '31: Sin Excedentes Individual – Consumo'},
    { code: '32', name: '32: Sin Excedentes Colectivo – Consumo'},
    { code: '33', name: '33: Sin Excedentes Colectivo con acuerdo de compensación – Consumo'},
    { code: '41', name: '41: Con excedentes y compensación Individual - Consumo'},
    { code: '42', name: '42: Con excedentes y compensación Colectivo– Consumo'},
    { code: '43', name: '43: Con excedentes y compensación Colectivo a través de red– Consumo'},
    { code: '51', name: '51: Con excedentes sin compensación Individual sin cto de SSAA en Red Interior– Consumo'},
    { code: '52', name: '52: Con excedentes sin compensación Colectivo sin cto de SSAA en Red Interior– Consumo'},
    { code: '53', name: '53: Con excedentes sin compensación Individual con cto SSAA en Red Interior– Consumo'},
    { code: '54', name: '54: Con excedentes sin compensación individual con cto SSAA en Red Interior– SSAA'},
    { code: '55', name: '55: Con excedentes sin compensación Colectivo con cto de SSAA en Red Interior– Consumo'},
    { code: '56', name: '56: Con excedentes sin compensación Colectivo con cto de SSAA en Red Interior– SSAA'},
    { code: '61', name: '61: Con excedentes sin compensación Individual con cto SSAA a través de red – Consumo'},
    { code: '62', name: '62: Con excedentes sin compensación individual con cto SSAA a través de red – SSAA'},
    { code: '63', name: '63: Con excedentes sin compensación Colectivo con cto de SSAA a través de red – Consumo'},
    { code: '64', name: '64: Con excedentes sin compensación Colectivo  con cto de SSAA a través de red – SSAA'},
    { code: '71', name: '71: Con excedentes sin compensación Individual con cto SSAA a través de red y red interior – Consumo'},
    { code: '72', name: '72: Con excedentes sin compensación individual con cto SSAA a través de red y red interior – SSAA'},
    { code: '73', name: '73: Con excedentes sin compensación Colectivo con cto de SSAA  a través de red y red interior – Consumo'},
    { code: '74', name: '74: Con excedentes sin compensación Colectivo con cto de SSAA a través de red y red interior - SSAA'}
])

supplies
    .controller('CalendarForSupplyCtrl', CalendarForSupplyCtrl)
    .controller('DeleteCalendarForSupplyCtrl', DeleteCalendarForSupplyCtrl)
    .controller('ElectricityMetersCtrl', ElectricityMetersCtrl)
    .controller('ListSuppliesCtrl', ListSuppliesCtrl)
    .controller('ReadingsCtrl', ReadingsCtrl)
    .controller('SCDoubleReportCtrl', SCDoubleReportCtrl)
    .controller('SCImportHistoryCtrl', SCImportHistoryCtrl)
    .controller('SCSimpleReportCtrl', SCSimpleReportCtrl)
    .controller('SCTabsCtrl', SCTabsCtrl)
    .controller('SipsCtrl', SipsCtrl)
    .controller('SupplyDetailCtrl', SupplyDetailCtrl)
    .controller('AtrRequestSupplyCtrl', AtrRequestSupplyCtrl)
    .service('CalendarForSupplyAPI', CalendarForSupplyAPI)
    .service('CNMCParser', CNMCParser)
    .service('ConsumptionParser', ConsumptionParser)
    .service('ConsumptionReport', ConsumptionReport)
    .service('ElectricityMeter', ElectricityMeter)
    .service('EonParser', EonParser)
    .service('IberdrolaParser', IberdrolaParser)
    .service('SipsParser', SipsParser)
    .service('SupplyAPI', SupplyAPI)
    .directive('consumptionList', consumptionList)
    //.directive('consumptionReading', consumptionReading)
    .directive('consumptionReport', consumptionReport)
    .directive('doubleReport', doubleReport)
    .directive('simpleReport', simpleReport)
