const BackgroundTasksCtrl = ['$scope', 'notificationCenter', '$http', '$rootScope',
        function ($scope, notificationCenter, $http, $rootScope) {
            var self = this;

            $scope.backgroundTasks = {};

            $http.get(API_URL + '/api/1.0/tasks/unfinished/')
                .then(function(data){
                    $scope.backgroundTasks = data.data;
                });

            notificationCenter.subscribe('BG_TASK_STARTS', function (type, data) {
                $scope.backgroundTasks.unfinished += 1
            });

            notificationCenter.subscribe('BG_TASK_ENDS', function (type, data) {
                $scope.backgroundTasks.unfinished -= 1

                if($scope.backgroundTasks.unfinished < 0){
                    $scope.backgroundTasks.unfinished = 0
                }
            });

        }
    ]

export {BackgroundTasksCtrl}
