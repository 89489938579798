const SupplyDetailCtrl = ['$scope', '$sce', '$compile', '$routeParams', '$filter', '$location', 'SupplyAPI', 'SUPPLY_RATES', 'SUPPLY_STATUSES', 'ENERGY_ORIGINS', 'UserInfo', 'stringUtils', 'Alert', 'SuppliesTypesMaster', 'ProgrammingUnitsMaster','CitiesMaster', 'CompaniesMaster', 'AUTOCONSUMPTION_TYPE',
    function ($scope, $sce, $compile, $routeParams, $filter, $location, SupplyAPI, SUPPLY_RATES, SUPPLY_STATUSES, ENERGY_ORIGINS, UserInfo, stringUtils, Alert, SuppliesTypesMaster, ProgrammingUnitsMaster, CitiesMaster, CompaniesMaster, AUTOCONSUMPTION_TYPE) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        $scope.SUPPLY_ELECTRICITY_METERS_TAB = require('../../templates/electricity-meters.html')
        $scope.SUPPLY_READINGS_TAB = require('../../templates/readings.html')
        $scope.SUPPLY_CONTRACTS_TAB = require('../../templates/supply-contracts.html')
        $scope.SUPPLY_ATR_TAB = require('../../templates/supply-atr-request.html')
        $scope.SUPPLY_CALENDARS_TAB = require('../../templates/supply-calendars.html')
        $scope.SUPPLY_DETAIL_LOGS_TAB = require('../../templates/supplies-detail-logs.html')

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.selectedReport = 'simple';
        $scope.rates = SUPPLY_RATES;
        $scope.statuses = SUPPLY_STATUSES;
        $scope.energyOrigins = ENERGY_ORIGINS;
        $scope.autoconsumptionTypes = AUTOCONSUMPTION_TYPE;

        $scope.types = [];

        $scope.loading = true;
        $scope.cups = $routeParams.cups;

        SupplyAPI.getSupplyDetail($scope.cups).then(function (supply) {
            $scope.supply = supply.data;
            $scope.loading = false;
            $scope.supplyStateFlag = true;
            if (!$scope.supply.measurement_point_type) {
                $scope.supply.measurement_point_type = '01';
            }
        });

        $scope.bt_measure_y_n = [{'id': 'S', 'name': 'Sí'}, {'id': 'N', 'name': 'No'}];

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        // Asignacion de STATE a uno que exista en el master de STATES
        $scope.$watchGroup(['supplyStateFlag', 'statesFlag'], function (newValues, oldValues, scope) {
            if (newValues.length > 0, newValues[0] && newValues[1]) {
                $scope.supply.state = stringUtils.updateState($scope.states, $scope.supply.state);
            }
        });

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        this.update_distributor = function () {
            if (!($scope.supply.distributor)) {
                var code = $scope.supply.cups.substring(2, 6);
                var l_code = $scope.supply.cups.substring(2, 8);
                if (!isNaN(l_code)) {
                    switch (parseInt(l_code)) {
                        case 3130:
                            code = "0029";
                            break;
                        case 3144:
                            code = "0396";
                            break;
                        case 3140:
                            code = "0024";
                            break;
                        case 3110:
                            code = "0023";
                            break;
                        case 3150:
                            code = "0288";
                            break;
                        case 3160:
                            code = "0363";
                            break;
                    }
                }
                $scope.supply.distributor = code;
            }
        };

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.showSimpleReport = function () {
            $scope.selectedReport = 'simple';
        };

        $scope.showComparativeReport = function () {
            $scope.selectedReport = 'comparative';
        };

        $scope.showConsumptionReport = function () {
            $scope.selectedReport = 'consumption';
        };

        $scope.powerActive = function (powerIndex) {
            var powerMatrix = {
                T20A: [false, true, false, false, false, false],
                T20DHA: [true, false, true, false, false, false],
                T21A: [false, true, false, false, false, false],
                T21DHA: [true, false, true, false, false, false],
                T30A: [true, true, true, false, false, false],
                T31A: [true, true, true, false, false, false],
                T31DHA: [true, true, true, false, false, false],
                T61: [true, true, true, true, true, true],
                T62: [true, true, true, true, true, true],
                T63: [true, true, true, true, true, true],
                T64: [true, true, true, true, true, true]
            };
            return (angular.isDefined($scope.supply) && angular.isDefined($scope.supply.rate) && $scope.supply.rate != '') ? !powerMatrix[$scope.supply.rate][powerIndex - 1] : true;
        };

        $scope.updateSupply = function (form) {
            form.$setUntouched();

            if (!$scope.supply.cie_issued_on) $scope.supply.cie_issued_on = null;
            if (!$scope.supply.cie_activated_on) $scope.supply.cie_activated_on = null;
            if (!$scope.supply.cie_finished_on) $scope.supply.cie_finished_on = null;

            self.update_distributor();
            delete $scope.supply.created_on;
            delete $scope.supply.modified_on;

            SupplyAPI.updateSupply($scope.supply, $scope.cups)
                .then(function (supply) {
                    Alert.createAlert('success', $translate('CUSTOMERS.CUSTOMER_UPDATED'), true);

                    if(supply.cups != $scope.cups) {
                        $location.path('/supplies/' + supply.cups);
                    }

                    $scope.supplt = supply;
                    form.$setPristine();
                });
        };

        $scope.canViewDetail = function () {
            return (UserInfo.hasPerm('supplies.retrieve_supplies'));
        };

        $scope.canViewContractsForSupply = function () {
            return (UserInfo.hasPerm('supplies.list_contractforsupplies') && UserInfo.hasPerm('supplies.retrieve_supplies'));
        };

        $scope.canViewReadings = function () {
            return (UserInfo.hasPerm('consumptions.list_electricitymeters'));
        };

        $scope.canViewListConsumptions = function () {
            return UserInfo.hasPerm('consumptions.list_consumption');
        };

        $scope.canViewAddConsumptions = function () {
            return (UserInfo.hasPerm('consumptions.add_consumptionreadings') && UserInfo.hasPerm('consumptions.change_consumptionreadings'));
        };

        $scope.canShowGeneralConsuptionsReports = function () {
            return (UserInfo.hasPerm('consumptions.list_simple_consumptiom_report') || UserInfo.hasPerm('consumptions.list_double_consumptiom_report'));
        };

        $scope.canShowSimpleReport = function () {
            return UserInfo.hasPerm('consumptions.list_simple_consumptiom_report');
        };

        $scope.canShowDoubleReport = function () {
            return UserInfo.hasPerm('consumptions.list_double_consumptiom_report');
        };

        $scope.canShowConsumptionReport = function () {
            return UserInfo.hasPerm('consumptions.download_consumption_report_csv');
        };

        $scope.canUpdateSupply = function () {
            return UserInfo.hasPerm('supplies.change_supply');
        };

        $scope.canViewCalendarsForSupply = function () {
            return (UserInfo.hasPerm('calendars.list_calendarforsupplies') && UserInfo.hasPerm('supplies.retrieve_supplies'));
        };

        $scope.enableEdition = function () {
            $scope.enable_edition = !$scope.enable_edition;
        };

        $scope.canAssignLabel = function () {
            return UserInfo.hasPerm('labels.add_labelledobject');
        };

        $scope.canDeleteAssignLabel = function () {
            return UserInfo.hasPerm('labels.delete_labelledobject');
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        CitiesMaster.loadCities().then(function(response){
            $scope.cities=response;
        });

        ProgrammingUnitsMaster.loadProgrammingUnits().then(function (response){
            $scope.programming_units = response;
        });

        CompaniesMaster.loadCompaniesDistributor().then(function (response){
            $scope.distributors = response;
        });

        CompaniesMaster.loadCompaniesCommercial().then(function (response){
            $scope.commercials = response;
        });

    }
]

export {SupplyDetailCtrl};