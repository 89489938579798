
const ItemToBeInvoicedForContractCtrl = 
        ['$scope', '$http', '$filter', '$routeParams', 'Alert', 'ProductCodeMaster', 'ContractAPI', 'UserInfo',
            function ($scope, $http, $filter, $routeParams, Alert, ProductCodeMaster, ContractAPI, UserInfo) {
                /***********************************************************************************************************
                 * Controller Attributes
                 ***********************************************************************************************************/
                var $translate = $filter('translate');
                var self = this;
                var id = $routeParams.id;

                /***********************************************************************************************************
                 * Scope Attributes
                 ***********************************************************************************************************/
                $scope.startDate = new Date();
                $scope.finishDate = new Date();
                $scope.validateDate = new Date();
                $scope.status = "DEF";
                $scope.selected_product = "";
                $scope.creationMode = false;
                $scope.ordering = {
                    "type": $translate('CONTRACTS.TYPE'),
                    "value": $translate('CONTRACTS.VALUE'),
                    "origin": $translate('CONTRACTS.ORIGIN'),
                    "origin_id": $translate('CONTRACTS.PROVIDER_INVOICE'),
                    "start_date": $translate('CONTRACTS.START_DATE'),
                    "finish_date":  $translate('CONTRACTS.FINISH_DATE'),
                    "validity_date": $translate('CONTRACTS.VALIDITY_DATE')
                };
                $scope.item_origins = [
                    {code: 'F11', label: 'F11'},
                    {code: 'FIX', label: 'FIX'},
                    {code: 'DEF', label: 'DEF'},
                    {code: 'MAN', label: 'MAN'}
                ];

                $scope.filters = {
                    provider_invoice: "",
                    origin_id: ""
                };

                $scope.searchFilters = {
                    'provider_invoice': $translate('PROVIDERS.INVOICE'),
                    'origin_id': $translate('INVOICES.ORIGIN_ID')
                };

                $scope.searchBy = Object.keys($scope.searchFilters)[0];
                $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];

                $scope.setSearchBy = function (val) {
                    $scope.filters[$scope.searchBy] = '';
                    $scope.searchBy = val;
                    $scope.searchByLabel = $scope.searchFilters[val];
                    $scope.filters[val] = $scope.searchQuery;
                };

                $scope.$watch('searchQuery', function (newVal, oldVal) {
                    $scope.filters[$scope.searchBy] = newVal;
                });

                /***********************************************************************************************************
                 * Scope Watchers
                 ***********************************************************************************************************/

                
                /***********************************************************************************************************
                 * Controller Methods
                 ***********************************************************************************************************/
                function resetForm() {
                    $scope.startDate = new Date();
                    $scope.finishDate = new Date();
                    $scope.validateDate = new Date();
                    $scope.value = "";
                    $scope.status = "DEF";
                    $scope.selected_product = "";
                    $scope.contractItemToBeInvoice.$setPristine();
                    $scope.contractItemToBeInvoice.$setUntouched();
                }

                /***********************************************************************************************************
                 * Scope Methods
                 ***********************************************************************************************************/
                $scope.toggleCreationItemMode = function () {
                    $scope.creationMode = !$scope.creationMode;
                    if (!$scope.creationMode){
                        resetForm();
                    }
                };

                $scope.deleteSelectedItems = function () {
                    let url = API_URL + '/api/1.0/contracts/' + id + '/items/delete/'
                    let data = {
                        'ids': Object.keys($scope.selected_item_to_be_invoiced)
                    }
                    $http.post(url, data).then(function (response) {
                        Alert.createAlert('success', $translate('CONTRACTS.ITEM_TO_BE_INVOICE_DELETED'), true);
                        $scope.listReload()
                        $scope.selected_item_to_be_invoiced = Object()
                    }).catch(function (error) {
                        $scope.listReload()
                        $scope.selected_item_to_be_invoiced = Object()
                    });

                };

                $scope.canAddItem = function () {
                    return UserInfo.hasPerm("invoices.add_penaltyitem") || UserInfo.hasPerm("invoices.add_itemtobeinvoiced");
                };

                $scope.createItemToBeInvoice = function () {
                    var data = {
                        "contract": id,
                        "type": $scope.selected_product.code,
                        "value": $scope.value,
                        "text": $scope.selected_product.name,
                        "status": $scope.status,
                        "start_date": $scope.startDate,
                        "finish_date": $scope.finishDate,
                        "validity_date": $scope.validateDate
                    };

                    ContractAPI.createItemToBeInvoice(data).then(function (data) {
                        $scope.listReload();
                        Alert.createAlert('success', $translate('CONTRACTS.ITEM_TO_BE_INVOICE_ADDED'), true);
                        resetForm();
                        $scope.creationMode = false;
                    }).catch(function (error) {
                        $scope.error_message = error.data.__all__[0];
                    });
                };

                /***********************************************************************************************************
                 * Controller Initialization
                 ***********************************************************************************************************/
                ProductCodeMaster.loadProductCodesNotForce().then(function (response){
                    $scope.products_codes = response;
                });


            }]

export {ItemToBeInvoicedForContractCtrl}
