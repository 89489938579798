function invoiceBulkRefundWithNewPrices(UserInfo, filtersUtils, Alert, $filter) {
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: require('../../templates/refund-new-prices-button.html'),
        controller: function ($scope, Task) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagConfirmNewPrices = false;
            $scope.listToRefundNewPrices = [];

            $scope.canAddInvoice = function() {
                return UserInfo.hasPerm('invoices.add_invoice')
            }

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToRefundNewPrices = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                let json = {
                    ids: ids
                };
                let task = new Task();
                task.type = 'IRCP';
                task.params = json;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.BULK_REFUND_INVOICES_NEW_PRICES_SUCCESS'), true);
                    $scope.backgroundTasks.push(data.data.id);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('INVOICES.BULK_REFUND_INVOICES_NEW_PRICES_ERROR'), true);
                })
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.confirmRefundNewPrices = function () {
                $scope.flagConfirmNewPrices = true;
            };

            $scope.clickNewPricesNo = function () {
                $scope.flagConfirmNewPrices = false;
            };

            $scope.clickNewPricesYes = function () {
                let ids = filtersUtils.getItemsToDownloadToInt($scope.listToRefundNewPrices);
                performRequest(ids);
            };
        }
    }
}

export { invoiceBulkRefundWithNewPrices }
