const InvoiceSeriesAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/invoices-series/';
        var self = this;

        function listInvoiceSeries(data) {
            var deferred = $q.defer();

            $http({
                url: url,
                method: "GET",
                params: data
            })
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function postListInvoiceSeries(data) {
            var deferred = $q.defer();

            $http["post"](url + 'list/', data)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function createInvoiceSeries(data) {
            var deferred = $q.defer();

            data.types_allowed = 1

            $http["post"](url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            listInvoiceSeries: listInvoiceSeries,
            postListInvoiceSeries: postListInvoiceSeries,
            createInvoiceSeries: createInvoiceSeries
        };
    }
]

export { InvoiceSeriesAPI }