import { Companies } from "./services/Companies"
import { companiesSelector } from "./directives/CompaniesSelector";

const companies = angular.module('companies', []);

companies.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

companies 
    .service("Companies", Companies)
    .directive("companiesSelector", companiesSelector)

export { companies }
