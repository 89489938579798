{
  "PROVIDERS": {
    "TITLE": "Providers list",
    "ALL": "All",
    "ANY": "Any",
    "NO_CLIENT": "Client n.",
    "NIF/CIF": "VAT Id",
    "NAME": "Name",
    "STATE": "State",
    "New Provider": "New Provider",
    "Delete": "Delete",
    "View": "View",
    "Name and surname": "Name and surname",
    "VAT Id": "Vat Id",
    "Create": "Create",
    "Cancel": "Cancel",
    "Yes": "Yes",
    "No": "No",
    "Accounting": "Accounting",
    "Email": "Email",
    "Phone": "Phone",
    "Mobile": "Mobile",
    "Fax": "Fax",
    "Address": "Address",
    "Address data": "Address data",
    "City": "City",
    "State": "State",
    "ZIP code": "ZIP code",
    "Update": "Update",
    "Provider": "Provider",
    "Comments": "Comments",
    "Files": "Files",
    "Invalid Vat Id": "Invalid Vat Id",
    "Name required": "Name required",
    "VAT Id is required": "VAT Id is required",
    "Provider Updated": "Provider Updated",
    "Do you want to delete": "Do you want to delete?",
    "Provider Data": "Provider Data",
    "Provider Deleted": "Provider Deleted",
    "ERROR_OCCURRED": "Provider couldn't be created",
    "INVOICES": "Provider invoices",
    "INVOICE": "Provider invoice",
    "RECONCILED": "Reconciled",
    "NOT_RECONCILED": "Not reconciled",
    "IDN": "IDN",
    "TAX": "Tax",
    "ACTIONS": "Actions",
    "DELETEQ": "Delete",
    "ELEMENTSQ": "elements?",
    "DOWNLOAD_CSV": "Download CSV",
    "Bulk download CSV": "Bulk download CSV success",
    "DOWNLOAD": "Download",
    "Bulk download generation Task success": "Bulk download generation Task success",
    "Bulk download generation Task error": "Bulk download generation Task error",
    "ALL_SELECTED": "All",
    "DELETED_PROVIDER_INVOICES": "Deleted  ",
    "DELETED_READINGS_ERROR": "Provider Invoices selected can not be deleted. There are consumption readings Invoiced for any selected provider invoice",
    "DELETED_NOT_FOUND_ERROR": "Provider Invoices selected can not be deleted. Provider Invoices not found",
    "EMISSION_DATE": "Emission date",
    "PROVIDER_INVOICES": "  provider invoices.  ",
    "NOT_DELETED_PROVIDER_INVOICES": " Provider invoices can't not be deleted.",
    "TAX_APPLIED": "Tax Applied",
    "BALANCE": "Balance",
    "CREATION_DATE": "Creation date",
    "TAX_BASE": "Tax base",
    "NORMAL": "Normal",
    "REFUND": "Refund",
    "REFUND_WITH_REISSUE": "Refund with reissue",
    "REISSUE": "Reissue",
    "ORIGIN_TYPE": "Origin type",
    "REMITTANCE": "Remittance",
    "PENDING": "Pending",
    "PAID": "Paid",
    "PROVIDER_STATUS_UPDATED": "Num. updated provider invoices: ",
    "SELECT_NEW_STATUS": "Select Status",
    "CHANGE_QUESTION": "Change?",
    "CHANGE_STATUS": "Change Status",
    "RECEIVE_PAPER_INVOICE": "Set date receive paper invoice",
    "PAYMENTS": "Payments",
    "CNMC_CODE": "CNMC Code (*)",
    "CNMC_REQUIRED": "CNMC code is required",
    "CNMC_CODE_INFO": "(*) View CNMC Codes ",
    "CNMC_CODE_INFO_HERE": "Here",
    "CHANGE_PROVIDER": " Change Provider",
    "PROVIDER_PROVIDER_UPDATED": "Num. updated provider in provider invoices: ",
    "RETURNED": "Returned",
    "SET_PAID_INVOICE_DATE": "Set invoice paid date",
    "PAID_DATE": "Paid date",
    "CHECKED": "Checked",
    "DISTRIBUTOR": "Distribuitor",
    "DOMICILED": "Domiciled",
    "NOT_DOMICILED": "Not Domiciled",
    "STATUS": "Status",
    "SELECT_BANK_AND_DATE": "Select bank and paid date",
    "BANK": "Payment bank",
    "CUPS": "CUPS",
    "APPLY_FILTER_DATA": "Apply date filter",
    "APPLIED_DATES_FILTER": "Applied dates filters:",
    "EXPIRATION_DATE": "Expiration date",
    "SELECT_EXPIRED_DATE": "Select expiration date",
    "SET_EXPIRATION_INVOICE_DATE": "Set invoice expiration date",
    "TYPE_C": "Complementary",
    "TYPE_G": "Regularize",
    "RECEIVE_PAPER_DATE": "Paper received date",
    "RESET_ITEMS": "Regenerate Items",
    "DUPLICATE_ITEMS": "Duplicate Items",
    "BANK_ACCOUNT": "Bank account",
    "SELECT_REFERENCE": "Select reference",
    "Calculo fecha vencimiento": "Due date calculation",
    "Fecha referencia desde": "Due date start date reference",
    "Número de días": "Number of days",
    "BRAND": "Brand",
    "DISTRIBUTOR_CODE": "Distributor Code",
    "DISTRIBUTOR_CODE_REQUIRED": "Distributor code is required",
    "CUPS_CODE": "Cups Code",
    "CUPS_CODE_REQUIRED": "Cups code is required",
    "CONTRACT_TO_DUPLICATE": "Set contrat id to duplicate items",
    "SET_CONTRACT_ID": "Set contract id (optional)",
    "FORCE_IMPORT": "Force import",
    "FORCE_IMPORT_TASK": "Created a task to force import",
    "SYNC_F1_FROM_IMPORTER": "Sync F1",
    "BULK_SYNC_F1_FROM_IMPORTER": "Sync xmls",
    "BULK_SYNC_F1_FROM_IMPORTER_ERROR": "Error in sync xmls task",
    "INVOICES_SYNC": "Invoices Sync",
    "DOWNLOAD_F1_FROM_IMPORTER": "Download F1",
    "PROCESS_F1": "Process F1",
    "BULK_DOWNLOAD_F1_FROM_IMPORTER": "Download xmls",
    "BULK_DOWNLOAD_F1_FROM_IMPORTER_ERROR": "Error in download xmls task",
    "BULK_PROCESS_F1": "Processing xmls",
    "BULK_PROCESS_F1_ERROR": "Error in process xmls task",
    "SYNC_F1_INFO": "Can filter by invoice type. If not all invoices are sync.",
    "DOWNLOADED": "Downloaded",
    "ERROR": "Error",
    "ERROR_PROCESS": "Process Error",
    "PROCESS_F1_INFO": "Can filter by invoice type. If not all invoices are process.",
    "REQUEST_DATE": "Date",
    "DISCART_XML": "Discart F1",
    "YES": "Sí",
    "NO": "No",
    "DISCARD_INVOICES": "Starting Discard invoices Task",
    "DISCARD_INVOICES_ERROR": "Error in Discard invoices Task",
    "DISCARD_QUESTION": "Discard Provider Invoice?"
  }
}