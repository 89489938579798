
const DuplicateContractCtrl = 
        ['$scope', '$location', '$routeParams', '$http', 'Alert', '$filter', 'CONTRACT_ORIGINS_TYPES', 'Contract',
            'CURRENT_SUPPLY_RATES', 'SUPPLY_POWER_PERIODS',
        function ($scope, $location, $routeParams, $http, Alert, $filter, CONTRACT_ORIGINS_TYPES, Contract,
                  CURRENT_SUPPLY_RATES, SUPPLY_POWER_PERIODS) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var contract = null;
            $scope.contract = null;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.drafts_origins = CONTRACT_ORIGINS_TYPES;
            $scope.rates = CURRENT_SUPPLY_RATES;

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$watch('id', function (newValue, oldValue) {
                if (newValue) {
                    Contract.get({idn: newValue}, function (contract) {
                        if (contract.count !== 0) {
                            $scope.duplicateContractValidation.contract_id.$setValidity('api', false);
                            $scope.duplicateContractValidation.contract_id.$error.api = $translate('CONTRACTS.This contract number already exists');
                        }
                        else {
                            delete $scope.duplicateContractValidation.contract_id.$error.api;
                            $scope.duplicateContractValidation.contract_id.$setValidity('api', true);
                        }
                    });
                }
            });
            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            Contract.get({id: $routeParams.id}, function (contract_data) {
                contract = contract_data;
                $scope.contract = contract_data

                $http.get(API_URL + '/api/1.0/contracts-product-rates/?ordering=activation_date&contract=' + contract.id)
                    .then(function (product_rates) {
                        contract.product_rate = product_rates.data.results[product_rates.data.results.length - 1].product_rate.code;
                    });
                
            });
            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
             $scope.powerActive = function (powerIndex, type) {
                if (type == 'supply')
                    return angular.isDefined($scope.supply) && $scope.supply != null &&
                        angular.isDefined($scope.supply.rate) && $scope.supply.rate != '' &&
                        !SUPPLY_POWER_PERIODS[$scope.supply.rate][powerIndex - 1];
                else if (type == 'contract')
                    return angular.isDefined($scope.contract) && $scope.contract != null &&
                        angular.isDefined($scope.contract.rate) && $scope.contract.rate != '' &&
                        !SUPPLY_POWER_PERIODS[$scope.contract.rate][powerIndex - 1];
                else
                    return true;
            };
            $scope.powerNormal = function () {
                if (angular.isDefined($scope.contract) && $scope.contract != null && angular.isDefined($scope.contract.rate)) {
                    for (var i = 1; i < 7; i++) {
                        if (!$scope.powerActive(i, 'contract')) {
                            if ($scope.contract['power_p' + i] !== undefined && $scope.normal_powers.indexOf($scope.contract['power_p' + i]) < 0) {
                                return false;
                            }
                        }
                    }
                    return true;
                }
                return true;
            };

            $scope.cleanPowers = function (type) {
                    $scope[type].power_p1 = 0;
                    $scope[type].power_p2 = 0;
                    $scope[type].power_p3 = 0;
                    $scope[type].power_p4 = 0;
                    $scope[type].power_p5 = 0;
                    $scope[type].power_p6 = 0;
                    $scope.power_values_change = false;
                };

            $scope.duplicateContract = function () {
                contract.status = 'DRF';
                contract.old_id = (contract.id).toString();
                contract.idn = $scope.id;
                contract.origin = $scope.origin.code;
                contract.company = $scope.company;
                contract.rate = $scope.contract.rate;

                if(!contract.consumption_from)
                {
                    delete contract.consumption_from;

                    delete contract.consumption_to;
                    delete contract.consumption_p1;
                    delete contract.consumption_p2;
                    delete contract.consumption_p3;
                    delete contract.consumption_p4;
                    delete contract.consumption_p5;
                    delete contract.consumption_p6;
                }
                delete contract.sepa_code;
                delete contract.sent_date;
                delete contract.app_date;
                delete contract.pending_date;
                delete contract.activation_date;
                delete contract.expiration_date;
                delete contract.expiration_date;
                
                $http.post(API_URL + '/api/1.0/contracts/', contract).then(function (contract_data) {
                    $location.path('/contracts/' + contract_data.data.id);
                    Alert.createAlert('success', $translate('CONTRACTS.Contract successfully duplicated'), true);

                }).catch(function (error) {
                    Alert.createAlert('danger', $translate('CONTRACTS.Contract error duplicating'), true);
                });
            };

            $scope.back = function () {
                $location.path('/contracts/' + $routeParams.id);
            };

        }]

export {DuplicateContractCtrl}