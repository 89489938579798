function banksSelector() {
    return {
        restrict: 'AE',
        scope: {
            bank: '=ngModel',
            disabled: '=ngDisabled',
            change: '=ngChange',
            company: '='
        },
        template: `
            <select ng-options="item as item.name for item in banks"
                    ng-model="bank" class="form-control input-sm banks-selector" ng-change="change" ng-disabled="disabled">
                <option value="">- {{ "CONTRACTS.ANY" | translate }} -</option>
            </select>
        `,
        controller: function ($scope, Bank) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            $scope.banks = []

            $scope.$watch("company", (newVal, oldVal) => {
                $scope.banks = []

                if(newVal){
                    Bank.get({"company": newVal}).$promise.then(
                        function (data) {
                            var arr = [];
                            
                            data.results.forEach(function (bank) {
                                arr.push(bank);
                            });
                            $scope.banks = arr;
                        }
                    );
                }
            })
        }
    }
}

export {banksSelector}
