function companiesSelector() {
    return {
        restrict: 'AE',
        scope: {
            company: '=ngModel',
            disabled: '=ngDisabled'
        },
        template: `
            <select ng-options="item.id as item.name for item in companies"
                    ng-model="company" class="form-control input-sm companies-selector" ng-disabled="disabled">
                <option value="">- {{ "CONTRACTS.ANY" | translate }} -</option>
            </select>
        `,
        controller: function ($scope, Companies) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            if($scope.company){
                $scope.company = parseInt($scope.company);
            }

            $scope.companies = []

            Companies.listCompanies()
                .then(function(response){
                    $scope.companies = response.results;
                })
                .catch(function(){})
        }
    }
}

export {companiesSelector}
