function providerInvoiceDiscardInvoice(UserInfo, Alert, filtersUtils, DateUtils, Task){
    return {
        restrict: 'AE',
        templateUrl: require('../../templates/provider-invoices-set-discard-invoice.html'),
        controller: function ($scope, $rootScope, $filter) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagChageDiscardConfirm = false;
            $scope.listToUpdate = [];
            $scope.discard = true;

            $scope.hasPerm = UserInfo.hasPerm('providers.add_providerinvoice');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var task = new Task();
                task.type = 'PDIN';
                task.params = {ids: ids, discard: $scope.discard};
                console.log(task.params)
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('PROVIDERS.DISCARD_INVOICES'), true);
                    $scope.discard = true;
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('PROVIDERS.DISCARD_INVOICES_ERROR'), true);
                    $scope.discard = true;
                });

            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.clickDiscardInvoices = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                console.log(ids)
                performRequest(ids);
            };

            $scope.confirmDiscard = function () {
                $scope.flagChageDiscardConfirm = true;
            };

            $scope.clickDiscardNo = function () {
                $scope.flagChageDiscardConfirm = false;
            };

        }
    }
}

export { providerInvoiceDiscardInvoice }
