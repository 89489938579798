function filtersUtils() {
    var self = this;


    this.buildJSONFilters = function (filters) {
        var filter = {};
        for (var element in filters){
            if (element == 'search' && filters[element] != '' && filters[element] != undefined)
                filter['q'] = filters[element];
            else
                if (filters[element] != '' && filters[element] != null && filters[element] != undefined)
                    filter[element] = filters[element];
        }

        return filter;
    };

    this.buildQueryFilters = function (filters){
        var filter = '';
        for (var element in filters){
            if (element == 'search' && filters[element] != '' && filters[element] != undefined)
                filter += '&q=' + filters[element];
            else
                if (filters[element] != '' && filters[element] != null && filters[element] != undefined)
                    filter += '&' + element + '=' + filters[element];
        }

        return filter;
    };

    this.serializeFilters = function (filters) {
        var str = [];
        for(var p in filters){
            if (filters[p]!= null && filters[p]!= undefined && filters[p] != '')
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(filters[p]));
        }
        return str.join("&");
    };

    this.getItemsToDownloadParams = function (name, items) {
        var params = '?';
        var toDownload = [];
        if (Object.keys(items).length !== 0) {
            angular.forEach(items, function (value, key) {
                if (value==true)
                    toDownload.push(key) ;
            });
        }
        params += name + '=' + toDownload;
        return params;
    };

    this.getItemsToDownload = function (items) {
        var toDownload = [];
        if (Object.keys(items).length !== 0) {
            angular.forEach(items, function (value, key) {
                if (value==true)
                    toDownload.push(key);
            });
        }
        return toDownload;
    };

    this.getItemsToDownloadToInt = function (items) {
        var toDownload = [];
        if (Object.keys(items).length !== 0) {
            angular.forEach(items, function (value, key) {
                if (value==true)
                    toDownload.push(parseInt(key));
            });
        }
        return toDownload;
    };
}

export {filtersUtils}
