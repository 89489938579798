{
  "ISSUES": {
    "TITLE": "Eventos",
    "TYPE": "Tipo",
    "CATEGORY": "Categoría",
    "NO_CONTRACT": "Nº Contrato",
    "NO_INVOICE": "Nº Factura",
    "SUPPLY_CUPS": "CUPS",
    "ALL": "Todo",
    "NO_ISSUE": "Nº Evento",
    "NO_COMPLAINT": "Nº Reclamación",
    "STATUS": "Estado",
    "CREATION_DATE_RANGE": "Fecha de creación",
    "LAST_MOD_DATE_RANGE": "Fecha última modificación",
    "LAST_STATUS_MOD_DATE_RANGE": "Fecha última modificación de estado",
    "SEARCH": "Buscar",
    "DATE_RANGE": "Fechas",
    "NO_FILTER": "No filtrar",
    "DATE_FROM": "Desde",
    "DATE_TO": "Hasta",
    "VIEW": "Ver",
    "LOGS":"Logs",
    "SYSTEM": "Sistema",
    "COMMENTS": "Comentarios",
    "FILES": "Archivos",
    "ISSUE_DATA": "Datos del evento",
    "ISSUE_NUMBER": "Nº Evento: ",
    "ENABLE_ISSUE_EDITION": "Desbloquear Edición",
    "UPDATE": "Actualizar",
    "CANCEL": "Cancelar",
    "ISSUE_INFO": "Información del Evento",
    "CREATION_DATE": "Fecha de creación",
    "LAST_MOD_DATE": "Fecha de última modificación",
    "LAST_STATUS_MOD_DATE": "Fecha de última modificación de estado",
    "TITLE_INFO": "Título",
    "TYPE_MANDATORY": "Debe incluir el tipo de evento",
    "CATEGORY_MANDATORY": "Debe incluir la categoría del evento",
    "OTHER_INFO": "Otros datos",
    "ISSUE_UPDATE_SUCCESSFULLY": "Evento actualizado con éxito.",
    "ANY": "Cualquiera",
    "ACTIONS": "Acciones",
    "CHANGE_STATUS": "Cambiar estado",
    "SELECT_TYPE": "Seleccionar Tipo Evento",
    "SELECT_CATEGORY": "Seleccionar Categoría",
    "SELECT_NEW_STATUS": "Seleccionar el Nuevo Estado",
    "DATE_CHANGE_STATUS": "Fecha de Cambio de Estado",
    "CHANGE_QUESTION": "¿Cambiar?",
    "YES": "Sí",
    "NO": "No",
    "ISSUES_STATUS_UPDATED": "Nº Eventos actualizados con éxito: ",
    "ERROR_REQUEST": "Se ha producido un error.",
    "BACK": "Volver",
    "DOWNLOADS": "Descargar",
    "DOWNLOAD_QUESTION": "¿Descargar?",
    "SELECT_DOWNLOAD_ACTION": "Seleccionar acción de descarga",
    "DELETE": "Eliminar",
    "ELEMENTS": "Elementos",
    "DELETED": "Eliminados eventos: ",
    "REQUEST_CODE": "Código de Solicitud",
    "SUPPLY_DISTRIBUTOR": "Distribuidora",
    "SELECT_STATUS": "Seleccionar Estado",
    "CIF": "CIF",
    "SOCIAL_REASON": "Razón Social",
    "PHONE": "Teléfono",
    "BULK_DOWNLOAD_CSV": "Descarga de eventos en xls en cola",
    "BULK_DOWNLOAD_GENERATION_TASK_ERROR": "Error en la Tarea de descarga de eventos en xls.",
    "DOWNLOAD_CSV": "Descargar eventos en XLS",
    "DUPLICATE_ISSUE": "Duplicar Evento",
    "DUPLICATE_QUESTION": "¿Duplicar Evento?",
    "DUPLICATED_ISSUE_SUCCESSFULLY": "Evento Duplicado. Nuevo Evento: ",
    "CHECKED": "Revisado",
    "PENDING": "Pendiente de Revisar",
    "CLOSED": "Cerrado",
    "CHANGE_CHECKED": "Cambiar Revisado",
    "SELECT_CHECKED_TYPE": "Seleccionar Tipo Revisado",
    "STATUS_PENDING": "Pendiente",
    "STATUS_SENT": "Enviado",
    "STATUS_ACCEPT": "Aceptado",
    "STATUS_REJECT": "Rechazado",
    "STATUS_ACTIVE": "Activado",
    "SELECT_MAXIMETER": "Seleccionar maxímetro",
    "COMPANY_NAME": "Compañía"
  }
}