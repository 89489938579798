function vatid() {
    var NIF_Letters = "TRWAGMYFPDXBNJZSQVHLCKET";
    var NIF_regExp = "^\\d{8}[a-zA-Z]{1}$";
    var CIF_regExp = "^[a-zA-Z]{1}\\d{7}[a-jA-J0-9]{1}$";

    var checkNIF = function (nif) {
        // Comprueba la longitud. Los DNI antiguos tienen 7 digitos.
        if ((nif.length != 8 ) && (nif.length != 9)) return false;
        if (nif.length == 8 ) nif = '0' + nif; // Ponemos un 0 a la izquierda y solucionado

        // Comprueba el formato
        var regExp = new RegExp(NIF_regExp);
        if (!nif.match(regExp)) return false;

        var charLet = nif.charAt(nif.length-1);
        var dni = nif.substring(0,nif.length-1);
        var letra = NIF_Letters.charAt(dni % 23);
        return (letra==charLet.toUpperCase());
    };

    // VALIDA TARJETA DE RESIDENCIA
    var checkTR = function (tr) {
        if ((tr.length!=10) && (tr.length!=9)) return false;
        if ((tr.charAt(0).toUpperCase() != "X") && (tr.charAt(0).toUpperCase() != "Y") && (tr.charAt(0).toUpperCase() != "Z")) return false;

        var leftNum = '0';
        if (tr.charAt(0).toUpperCase() == "Y") leftNum = '1';
        else if (tr.charAt(0).toUpperCase() == "Z") leftNum = '2';

        if (tr.length==9) {
            return checkNIF(leftNum + tr.substring(1,tr.length));
        } else {
            return checkNIF(tr.substring(1,tr.length));
        }
    };

    // VALIDA TARJETA DE RESIDENCIA
    var checkCIF = function (cif) {
        var v1 = new Array(0,2,4,6,8,1,3,5,7,9);
        var tempStr = cif.toUpperCase(); // pasar a mayúsculas
        var temp = 0;
        var temp1;
        var dc;

        // Comprueba el formato
        var regExp=new RegExp(CIF_regExp);
        if (!tempStr.match(regExp)) return false;    // Valida el formato?
        if (!/^[ABCDEFGHJKLMNPQRSUVW]/.test(tempStr)) return false;  // Es una letra de las admitidas ?

        for(let i = 2; i <= 6; i += 2 ) {
            temp = temp + v1[ parseInt(cif.substr(i-1,1)) ];
            temp = temp + parseInt(cif.substr(i,1));
        }
        temp = temp + v1[ parseInt(cif.substr(7,1)) ];
        temp = (10 - ( temp % 10));
        if (temp==10) temp=0;
        dc  = cif.toUpperCase().charAt(8);
        return (dc==temp) || (temp==1 && dc=='A') || (temp==2 && dc=='B') || (temp==3 && dc=='C') || (temp==4 && dc=='D') || (temp==5 && dc=='E') || (temp==6 && dc=='F') || (temp==7 && dc=='G') || (temp==8 && dc=='H') || (temp==9 && dc=='I') || (temp==0 && dc=='J');
    };

    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ctrl) {
            ctrl.$validators.vatid = function (modelValue, viewValue) {
                if (scope.document_type !== undefined && scope.document_type !== 'NIF') {
                    return true;
                }

                if (scope.customer !== undefined && scope.customer !== null) {
                    if (scope.customer.document_type && scope.customer.document_type !== 'NIF')  {
                        return true;
                    }
                }                
                if (ctrl.$isEmpty(modelValue)) {
                    // consider empty models to be invalid
                    return true;
                }

                if (checkCIF(modelValue))  { // Comprueba el CIF
                    return true;
                } else if (checkTR(modelValue)) { // Comprueba tarjeta de residencia
                    return true;
                } else if (checkNIF(modelValue)) { // Comprueba el NIF
                    return true;
                } else  {           // Si no pasa por ninguno es false.
                    return false;
                }

            };
        }

    };
}

export { vatid }
