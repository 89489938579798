function brandsSelector() {
    return {
        restrict: 'AE',
        scope: {
            brand: '=ngModel',
            disabled: '=ngDisabled'
        },
        template: `
            <select ng-options="item.code as item.name for item in brands"
                    ng-model="brand" class="form-control input-sm brands-selector" ng-disabled="disabled">
                <option value="">- {{ "CONTRACTS.ANY" | translate }} -</option>
            </select>
        `,
        controller: function ($scope, BrandsList) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            $scope.brands = []

            BrandsList.all()
                .then(function(response){
                    $scope.brands = response;
                })
                .catch(function(){})
        }
    }
}

export {brandsSelector}
