
// Factories
import { Invoice } from './services/Invoice'
import { InvoiceSeries } from './services/InvoiceSeries'
import { Bank } from './services/Bank'

// Services
import { InvoiceSeriesAPI } from './services/InvoiceSeriesAPI'
import { InvoiceDeferredAPI } from './services/InvoiceTotalForDefered'
import { ItemToBeInvoicedAPI } from './services/ItemToBeInvoicedAPI'

// Controllers
import {AddPaymentInvoiceCtrl} from './controllers/AddPaymentInvoiceCtrl'
import {AddReceiptInvoiceCtrl} from './controllers/AddReceiptInvoiceCtrl'
import {CreateInvoiceCtrl} from './controllers/CreateInvoiceCtrl'
import {CreateInvoiceSeriesCtrl} from './controllers/CreateInvoiceSeriesCtrl'
import {InvoiceDetailCtrl} from './controllers/InvoiceDetailCtrl'
import {InvoicePaymentDetailCtrl} from './controllers/InvoicePaymentDetailCtrl'
import {InvoiceReadingsController} from './controllers/InvoiceReadingsController'
import {InvoicesAutoCtrl} from './controllers/InvoicesAutoCtrl'
import {InvoicesCtrl} from './controllers/InvoicesCtrl'
import {InvoiceSeriesDetailCtrl} from './controllers/InvoiceSeriesDetailCtrl'
import {InvoiceSeriesListCtrl} from './controllers/InvoiceSeriesListCtrl'
import {InvoicesFeesCtrl} from './controllers/InvoicesFeesCtrl'

// Directives
import { electronicInvoice } from './directives/ElectronicInvoice'
import { invoiceDeletion } from './directives/InvoiceDeletion'
import { invoiceRefund } from './directives/InvoiceRefund'
import { invoiceDuplicate } from './directives/InvoiceDuplicate'
import { invoiceBulkDuplicate } from './directives/InvoiceBulkDuplicate'
import { bulkInvoiceRefund } from './directives/BulkInvoiceRefund'
import { invoiceSetDefinitive } from './directives/InvoiceSetDefinitive'
import { invoiceSetDraft } from './directives/InvoiceSetDraft'
import { invoiceSetPaid } from './directives/InvoiceSetPaid'
import { invoiceSetRemitDate } from './directives/InvoiceSetRemitDate'
import { invoiceSetSent } from './directives/InvoiceSetSent'
import { invoiceSetUnpaid } from './directives/InvoiceSetUnpaid'
import { invoicesLines } from './directives/InvoicesLines'
import { invoiceBulkRefundWithNewPrices } from './directives/InvoiceBulkRefundWithNewPrices'
import { invoiceRefundWithNewPrices } from './directives/InvoiceRefundWithNewPrices'


const invoices = angular.module('invoices', ['pascalprecht.translate', ]);

invoices.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

invoices.value('INVOICES_TYPES', {
    A: 'Abono',
    B: 'Abono con refactura',
    R: 'Refactura',
    C: 'Complementaria',
    G: 'Regularizadora',
    N: 'Normal',
    'R,B': 'Abono/Refactura',
    E: 'Estimada',
    'EST': 'Estimada/Abono/N',
    X: 'Cierre estimada',
    M: 'Manual',
    U: 'Ajuste por Impagos',
    'RU': 'R. de ajuste por impagos',
    'EG': 'Regularizadora de estimada'
});

invoices.value('INVOICES_CHANGEABLE_TYPES', {
    A: 'Abono',
    B: 'Abono con refactura',
    R: 'Refactura',
    C: 'Complementaria',
    G: 'Regularizadora',
    N: 'Normal',
    E: 'Estimada',
    X: 'Cierre estimada',
    M: 'Manual',
    U: 'Ajuste por Impagos'
});

invoices.value('INVOICES_ORIGINS_TYPES', {
    '01': 'Normal',
    '02': 'Modificación de Contrato',
    '03': 'Baja de Contrato',
    '04': 'Derechos de Contratación',
    '05': 'Depósito de Garantía',
    '06': 'Inspección',
    '07': 'Atenciones (verificaciones, ...)',
    '08': 'Indemnización',
    '09': 'Intereses de demora',
    '10': 'Servicios'
});

invoices.value('DOMICILED_OPTIONS', {
    'true': 'Sí',
    'false': 'No'
});

invoices.value('INVOICES_COVERS', [
    {code: 'DEF', label: 'Por defecto'},
    {code: 'NOB', label: 'Sin observaciones'},
    {code: 'Q11', label: 'Q101'}
]);

invoices
    .factory('Invoice', Invoice)
    .factory('InvoiceSeries', InvoiceSeries)
    .factory('Bank', Bank)
    .service('InvoiceSeriesAPI', InvoiceSeriesAPI)
    .service('InvoiceDeferredAPI', InvoiceDeferredAPI)
    .service('ItemToBeInvoicedAPI', ItemToBeInvoicedAPI)
    .controller('AddPaymentInvoiceCtrl', AddPaymentInvoiceCtrl)
    .controller('AddReceiptInvoiceCtrl', AddReceiptInvoiceCtrl)
    .controller('CreateInvoiceCtrl', CreateInvoiceCtrl)
    .controller('CreateInvoiceSeriesCtrl', CreateInvoiceSeriesCtrl)
    .controller('InvoiceDetailCtrl', InvoiceDetailCtrl)
    .controller('InvoicePaymentDetailCtrl', InvoicePaymentDetailCtrl)
    .controller('InvoiceReadingsController', InvoiceReadingsController)
    .controller('InvoicesAutoCtrl', InvoicesAutoCtrl)
    .controller('InvoicesCtrl', InvoicesCtrl)
    .controller('InvoiceSeriesDetailCtrl', InvoiceSeriesDetailCtrl)
    .controller('InvoiceSeriesListCtrl', InvoiceSeriesListCtrl)
    .controller('InvoicesFeesCtrl', InvoicesFeesCtrl)
    .directive('electronicInvoice', electronicInvoice)
    .directive('invoiceDeletion', invoiceDeletion)
    .directive('invoiceRefund', invoiceRefund)
    .directive('invoiceDuplicate', invoiceDuplicate)
    .directive('invoiceBulkDuplicate', invoiceBulkDuplicate)
    .directive('bulkInvoiceRefund', bulkInvoiceRefund)
    .directive('invoiceSetDefinitive', invoiceSetDefinitive)
    .directive('invoiceSetDraft', invoiceSetDraft)
    .directive('invoiceSetPaid', invoiceSetPaid)
    .directive('invoiceSetRemitDate', invoiceSetRemitDate)
    .directive('invoiceSetSent', invoiceSetSent)
    .directive('invoiceSetUnpaid', invoiceSetUnpaid)
    .directive('invoicesLines', invoicesLines)
    .directive('invoiceBulkRefundWithNewPrices', invoiceBulkRefundWithNewPrices)
    .directive('invoiceRefundWithNewPrices', invoiceRefundWithNewPrices)

export { invoices }
