const IssueResultDetailCtrl = ['$scope', '$filter', '$routeParams', 'UserInfo', 'IssueAPI', 'Alert', 'eventInfoService',
    function ($scope, $filter, $routeParams, UserInfo, IssueAPI, Alert, eventInfoService) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.id = $routeParams.id;

        $scope.loading_detail = true;
        $scope.enable_edition = false;

        $scope.order_item = "order";
        $scope.order_reverse = false;

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        $scope.$watchGroup(['issue.type', 'issue.category'],
            function (newValues, oldValues, scope) {
                if (newValues[0] && newValues[1]){
                    eventInfoService.getEventStatus(newValues[0], newValues[1])
                        .then(function (issue) {
                            $scope.status_types  = issue;
                        });
                }
            }
        );

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        this.formatIssueInfo = function (issue) {
            var metadata = JSON.parse(JSON.stringify(eval('(' + issue.metadata + ')')));

            $scope.issue = issue;
            $scope.issue.metadata = metadata;
            $scope.issue.creation_date = new Date($scope.issue.creation_date);
            $scope.issue.last_mod_date = new Date($scope.issue.last_mod_date);
            $scope.issue.last_status_mod_date = new Date($scope.issue.last_status_mod_date);
        };

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.getIssue = function () {
            $scope.loading_detail = true;

            IssueAPI.getIssueDetail($scope.id)
                .then(function (issue) {
                    self.formatIssueInfo(issue);
                    $scope.loading_detail = false;
                });
        };

        $scope.updateIssue = function () {
            $scope.submited = false;

            // Built data to sent
            var issue_data = {};
            issue_data.title = $scope.issue.title;
            issue_data.type = $scope.issue.type;
            issue_data.category = $scope.issue.category;
            issue_data.status = $scope.issue.status;
            issue_data.contract = $scope.issue.contract
            issue_data.supply = $scope.issue.supply
            issue_data.request_code = $scope.issue.request_code

            // Built data dict
            var data = {};
            for (var key in $scope.issue.metadata.fields){
                if ($scope.issue.metadata.fields[key].type == "date" &&
                    $scope.issue.metadata.fields[key].value == null &&
                    $scope.issue.metadata.fields[key].required == "false") {
                    continue;
                }
                data[key] = $scope.issue.metadata.fields[key].value;
            }
            issue_data.data = data;

            IssueAPI.setIssueDetail($scope.id, issue_data)
                .then(function (issue) {
                    $scope.submited = true;
                    // Update object
                    self.formatIssueInfo(issue);
                    // Disable mode
                    $scope.enable_edition = false;

                    Alert.createAlert('success', $translate('ISSUES.ISSUE_UPDATE_SUCCESSFULLY'), true);
                }).catch(function (error) {
                    $scope.submited = true;
                    for (var k in error) {
                        $scope.issueForm[k].$error.api = error[k][0];
                    }
                });
        };

        $scope.enableEdition = function () {
            $scope.enable_edition = !$scope.enable_edition;
        };

        $scope.canEditIssue = function () {
            return UserInfo.hasPerm('issues.change_issue');
        };

        $scope.canListFiles = function () {
            return UserInfo.hasPerm('issues.list_issues_files');
        };

        $scope.canIssueMakeActions= function () {
            return this.can_duplicate_issue();
        };

        $scope.can_duplicate_issue = function () {
            return UserInfo.hasPerm('issues.duplicate_issue');
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        $scope.getIssue();

    }
]

export { IssueResultDetailCtrl }
