const BankCtrl = ['$scope', '$filter', 'Bank', 'Alert', 'UserInfo',
    function ($scope, $filter, Bank, Alert, UserInfo) {
        var $translate = $filter('translate');

        var self = this;

        $scope.mode = 'list';

        $scope.ordering = {
            "name": $translate('BANKS.BANK'),
            "credit_limit": $translate('BANKS.CREDIT_LIMIT')
        };

        $scope.getCreditPercentage = function(used, limit){
            if (limit <= 0) return 0;
            return Math.round((used * 100) / limit);
        };

        $scope.showCreateForm = function () {
            $scope.mode = 'create';
        };

        $scope.hideCreateForm = function () {
            $scope.mode = 'list';
        };

        $scope.resetForm = function(){
            $scope.bank.name = "";
            $scope.bank.account = "";
            $scope.bank.suffix = "";
            $scope.bank.credit_limit = "";
            $scope.bank.expiration_date = "";
            $scope.createBankForm.$setPristine();
            $scope.createBankForm.$setUntouched();
        };

        $scope.createBank = function () {
            var bank = new Bank();
            bank.name = $scope.bank.name;
            bank.account = $scope.bank.account;
            bank.credit_limit = $scope.bank.credit_limit;
            bank.expiration_date = $scope.bank.expiration_date;
            bank.suffix = $scope.bank.suffix;
            bank.company = $scope.filters.company;

            bank.$save({code: ""}, function() {
                    $scope.listReload();
                    $scope.resetForm();
                    Alert.createAlert('success', $translate('INVOICES.CREATE_BANK'), true);
                    $scope.loading = false;
                }).catch(function() {
                    Alert.createAlert('danger', $translate('INVOICES.CREATE_BANK_ERROR'), true);
                    $scope.loading = false;
                }
            );
        };

        $scope.deleteBank = function() {
            $scope.action = 'delete';
        };

        $scope.cancelDeleteProduct = function() {
            $scope.action = 'detail';
        };

        $scope.canCreateBank = function () {
            return UserInfo.hasPerm('banks.add_bank');
        };

    }
]

export { BankCtrl }
