const ProductRatesListCtrl = ['$scope', '$filter', 'UserInfo',
    function ($scope, $filter, UserInfo) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.products_rates = [];
        $scope.ordering = {
            "code": $translate('PRODUCTS.CODE'),
            "name": $translate('PRODUCTS.NAME')
        };
        $scope.filters = {
            search: "",
            visibility: "",
            "not_in_force": "",
            company: ""
        };
        $scope.statuses = [
            { code: 'VISIBLE', label: $translate('PRODUCTS.VISIBLE')},
            { code: 'INVISIBLE', label: $translate('PRODUCTS.INVISIBLE')}
        ];

        $scope.validity = [
            { code: '0', label: $translate('PRODUCTS.IN_FORCE')},
            { code: '1', label: $translate('PRODUCTS.NOT_IN_FORCE')}
        ];

        $scope.mode = 'list';

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        $scope.$on('refreshRates', function (event) {
            $scope.listReload();
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.getStyles = function(result){
            var styles = ''
            if(result.visibility === 'VISIBLE')
                styles += ' '+'success';
            if(result.visibility === 'INVISIBLE')
                styles += ' '+'danger';

            if(styles ===''){
                styles += ' '+'info';
            }

            return styles
        };

        $scope.showCreateRateForm = function() { $scope.mode = 'create'; };
        $scope.hideCreateRateForm = function() { $scope.mode = 'list'; };

        $scope.canCreateProductRate = function () {
            return (UserInfo.hasPerm("products.add_productrate"));
        };

    }
]

export { ProductRatesListCtrl }
