const AgreementDetailCtrl = ['$scope', '$routeParams', '$filter', '$timeout', 'SUPPLY_RATES', 'UserInfo', 'Alert', 'stringUtils', 'StatesMaster', 'CitiesMaster','BrandsMaster', 'AgreementAPI', 'addressUtils',
    function ($scope, $routeParams, $filter, $timeout, SUPPLY_RATES, UserInfo, Alert, stringUtils, StatesMaster, CitiesMaster, BrandsMaster, AgreementAPI, addressUtils) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        $scope.AGREEMENTS_CONTRACT_TAB = require('../../templates/agreement-contract-data.html')
        $scope.AGREEMENTS_USER_TAB = require('../../templates/agreement-user-management.html')
        $scope.AGREEMENTS_LIST_PRICES_RATE_TAB = require('../../templates/agreements-list-prices-rate.html')
        $scope.AGREEMENTS_CREATE_PRICE_RATE_TAB = require('../../templates/agreements-create-price-rate.html')
        $scope.AGREEMENTS_LIST_PRICES_CONTRACT_TAB = require('../../templates/agreements-list-prices-contract.html')
        $scope.AGREEMENTS_CREATE_PRICE_CONTRACT_TAB = require('../../templates/agreements-create-price-contract.html')
        $scope.AGREEMENTS_FEES_TAB = require('../../templates/agreements-fees-list.html')
        $scope.AGREEMENTS_LOGS_TAB = require('../../templates/agreements-detail-logs.html')


        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        var supply_rates = [];
        angular.copy(SUPPLY_RATES, supply_rates);
        var t20td = supply_rates.filter(rate => rate.code==='T20TD').pop()
        supply_rates[supply_rates.indexOf(t20td)] = { code: 'T20TD', name: '2.0TD ≤ 10 kW'}
        supply_rates.push({ code: 'F21TD', name: '2.0TD > 10 kW' });
        $scope.rates = supply_rates;
        $scope.types = [
            {code: 'ANN', name: $translate('AGREEMENTS.ESTIMATED_ANNUAL_CONSUMPTION')},
            {code: 'MON', name: $translate('AGREEMENTS.MONTHLY_CONSUMPTION')},
            {code: 'CON', name: $translate('AGREEMENTS.CONSUMPTION_BASED')}
        ];

        $scope.statuses = [
            {code: 'ACT', label: $translate('AGREEMENTS.ACTIVE')},
            {code: 'PEN', label: $translate('AGREEMENTS.PENDING')},
            {code: 'INA', label: $translate('AGREEMENTS.INACTIVE')}
        ];

        $scope.payment_methods = [
            {code: 'TRF', label: $translate('INVOICES.TRANSFER')},
            {code: 'DBT', label: $translate('INVOICES.DEBIT')},
            {code: 'B2B', label: $translate('INVOICES.DEBIT_B2B')},
            {code: 'GBT', label: $translate('INVOICES.DEBIT_GBT')}
        ];

        $scope.renewals_types = [
            {code: 'NOR', label: $translate('AGREEMENTS.NORMAL')},
            {code: 'REN', label: $translate('AGREEMENTS.RENTAL')}
        ];

        $scope.loading_detail = true;
        $scope.loading_prices = true;
        $scope.code = $routeParams.code;

        $scope.saving = false;
        $scope.enable_edition = false;
        $scope.enable_contact_edition = false;
        $scope.brands = [];

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        // Asignacion de STATE a uno que exista en el master de STATES
        $scope.$watchGroup(['agreementStateFlag', 'statesFlag', 'brandsFlag'], function (newValues, oldValues, scope) {
            if (newValues[0] && newValues[1]) {
                $scope.agreement.state = stringUtils.updateState($scope.states, $scope.agreement.state);
            }
        });

        $scope.$watch('agreement.activation_date', function (newValue, oldValue) {
            if ($scope.agreement)
                $scope.agreement.activation_date = ($scope.agreement.activation_date == "") ? null  : $scope.agreement.activation_date;
        });

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        this.cleanAgreementFormErrors = function () {
            for (var item in $scope.updateAgreement) {
                if (item.substring(0, 1) != '$') {
                    for (let key in $scope.updateAgreement[item].$error) {
                        $scope.updateAgreement[item].$setValidity(key, true);
                    }
                }
            }
        };

        this.initializeUserManagement = function () {
            if ($scope.agreement.professionals_zone != null && $scope.agreement.professionals_zone != '')
                $scope.professionalZone = $scope.agreement.professionals_zone.split(",");
            else
                $scope.professionalZone = [];

            if ($scope.agreement.customers_zone != null && $scope.agreement.customers_zone != '')
                $scope.customerZone = $scope.agreement.customers_zone.split(",");
            else
                $scope.customerZone = [];

            if ($scope.agreement.related_agreements != null && $scope.agreement.related_agreements != '')
                $scope.relatedAgreements = $scope.agreement.related_agreements.split(",");
            else
                $scope.relatedAgreements = [];

            // Added for renewal selectable email
            const webUsers = $scope.professionalZone.concat($scope.customerZone);
            $scope.renewalEmails = webUsers.filter(function(item, pos) { return webUsers.indexOf(item) === pos })
        };

        this.getPrices = function () {
            return AgreementAPI.getAgreementPrice($scope.code)
                .then(function (fees_prices) {
                    $scope.agreement.fees_prices = fees_prices.reduce(function (memo, item) {
                            if (item.contract == null) {
                                memo['rate'].push(item);
                            } else if (item.rate == null) {
                                item['contract'] = parseInt(item['contract']);
                                memo['contract'].push(item);
                            }

                            return memo;
                        }, {'rate': [], 'contract':[]});

                    $scope.agreement.fees_prices.delete_mode = false;
                    $scope.agreement.fees_prices.edit_mode = false;
                    $scope.loading_prices = false;
                });
        };

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.getAgreement = function () {
            $scope.loading_detail = true;

            AgreementAPI.getAgreementDetail($scope.code)
                .then(function (agreement) {
                    $scope.agreement = agreement;
                    $scope.code = agreement.code;
                    $scope.loading_detail = false;
                    $scope.agreementStateFlag = true;
                    if ($scope.agreement.bank_account != null){
                        if ($scope.agreement.bank_account.length != 20 && $scope.agreement.bank_account.length != 24)
                            $scope.agreement.bank_account = null;
                    }
                    self.initializeUserManagement();
                    return AgreementAPI.getAgreementPrice($scope.code);
                })
                .then(function (prices) {
                    $scope.agreement.fees_prices = prices.reduce(function (memo, item) {
                            if (item.contract == null) {
                                memo['rate'].push(item);
                            } else if (item.rate == null) {
                                item['contract'] = parseInt(item['contract']);
                                memo['contract'].push(item);
                            }

                            return memo;
                        }, {'rate': [], 'contract':[]});

                    $scope.agreement.fees_prices.delete_mode = false;
                    $scope.agreement.fees_prices.edit_mode = false;
                    $scope.loading_prices = false;
                });

        };

        $scope.saveAgreement = function () {
            self.cleanAgreementFormErrors();
            Alert.closeAlert();
            $scope.saving = true;

            delete $scope.agreement.customers_zone;
            delete $scope.agreement.professionals_zone;
            delete $scope.agreement.related_agreements;

            AgreementAPI.setAgreementDetail($scope.code, $scope.agreement)
                .then(function (data) {
                    $scope.saving = false;
                    Alert.createAlert('success', $translate('AGREEMENTS.SUCCESS_AGREEMENT_UPDATED'), true);
                    $scope.enableEdition();
                }).catch(function (error) {
                    for (var k in error) {
                        $scope.error_message = error[k];
                        if (k == 'non_field_errors') {
                            $scope.error_message = error[k][0];
                        }
                        $scope.updateAgreement[k].$error.api = error[k][0];
                    }
                    $scope.saving = false;
                });
        };

        $scope.saveAgreementContactData = function () {
            var contact_data = {};
            contact_data.contact_name = $scope.agreement.contact_name;
            contact_data.contact_surname_1 = $scope.agreement.contact_surname_1;
            contact_data.contact_surname_2 = $scope.agreement.contact_surname_2;
            contact_data.contact_address = $scope.agreement.contact_address;
            contact_data.contact_city = $scope.agreement.contact_city;
            contact_data.contact_state = $scope.agreement.contact_state;
            contact_data.contact_zip = $scope.agreement.contact_zip;
            contact_data.contact_phone = $scope.agreement.contact_phone;
            contact_data.contact_email = $scope.agreement.contact_email;
            contact_data.remittance_next_month = $scope.agreement.remittance_next_month;
            if ($scope.agreement.remittance_day != null){
                contact_data.remittance_day = $scope.agreement.remittance_day;
            }
            else {
                contact_data.remittance_day = null;
            }
            if ($scope.agreement.default_contract_payment_account != null){
                if ($scope.agreement.default_contract_payment_account.length != 20 && $scope.agreement.default_contract_payment_account.length != 24)
                    contact_data.default_contract_payment_account = null;
                else
                    contact_data.default_contract_payment_account = $scope.agreement.default_contract_payment_account;
            }
            else
                contact_data.default_contract_payment_account = null;
            contact_data.default_contract_payment_method = $scope.agreement.default_contract_payment_method;
            contact_data.receive_paper_invoices = $scope.agreement.receive_paper_invoices;
            contact_data.contact_observations = $scope.agreement.contact_observations;
            contact_data.renewal_email = $scope.agreement.renewal_email;
            contact_data.debt_email = $scope.agreement.debt_email;
            contact_data.type = $scope.agreement.type;

            AgreementAPI.setAgreementContactData($scope.code, contact_data)
                .then(function (data) {
                    Alert.createAlert('success', $translate('AGREEMENTS.SUCCESS_AGREEMENT_UPDATED'), true);
                    $scope.enableContactEdition();
                });
        };

        $scope.activeDeletePrice = function (price) {
            price.delete_mode ? price.delete_mode = false : price.delete_mode = true;
        };

        $scope.deletePrice = function (price) {
            AgreementAPI.deleteAgreementPrice($scope.code, price.id)
                .then(function () {
                    Alert.createAlert('success', $translate('AGREEMENTS.PRICE_DELETED'), true);
                    self.getPrices();
                });
        };

        $scope.editPriceRequest = function (index, price) {
            AgreementAPI.setAgreementPrice($scope.code, price.id, price)
                .then(function (data) {
                    Alert.createAlert('success', $translate('AGREEMENTS.SUCCESS_UPDATED'), true);
                }).catch(function (error) {
                    price.error = error.data.__all__[0];
                });
        };

        $scope.enableEdition = function () {
            $scope.enable_edition = !$scope.enable_edition;
        };

        $scope.enableContactEdition = function () {
            $scope.enable_contact_edition = !$scope.enable_contact_edition;
        };

        $scope.canListFiles = function () {
            return UserInfo.hasPerm('agreements.list_agreements_files');
        };

        $scope.canShowPrices = function () {
            return UserInfo.hasPerm('agreements.list_agreementfeeprices');
        };

        $scope.canDelete = function () {
            return UserInfo.hasPerm('agreements.delete_agreementfeeprice');
        };

        $scope.canEdit = function () {
            return UserInfo.hasPerm('agreements.change_agreementfeeprice');
        };

        $scope.$on('refreshPrices', function (event) {
            self.getPrices()
        });

        $scope.canEditAgreement = function () {
            return UserInfo.hasPerm('agreements.change_agreement');
        };

        $scope.canEditAgreementContactData = function () {
            return UserInfo.hasPerm('agreements.can_edit_contact_data');
        };

        $scope.canShowFees = function () {
            return UserInfo.hasPerm('fees.list_fees');
        };

        $scope.canShowCommercialObservations = function () {
            return UserInfo.hasPerm('agreements.can_edit_commercial_observation');
        };

        $scope.canAssignLabel = function () {
            return UserInfo.hasPerm('labels.add_labelledobject');
        };

        $scope.canDeleteAssignLabel = function () {
            return UserInfo.hasPerm('labels.delete_labelledobject');
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        $scope.getAgreement();

        CitiesMaster.loadCities().then(function(response){
            $scope.cities=response;
        });

        StatesMaster.loadStates().then(function (response) {
            $scope.states = response;
            $scope.statesFlag = true;
        });
        BrandsMaster.loadBrands().then(function(response){
            $scope.brands = response;
            $scope.brandsFlag = true;
        });

        $scope.$on('refreshAgreementDetail', function (event) {
            $scope.getAgreement();
        });
    }
]

export {AgreementDetailCtrl}
