const ReceiptActionsCtrl = ['$scope', '$rootScope', '$filter', 'UserInfo', 'Receipt', 'Alert',
    function ($scope, $rootScope, $filter, UserInfo, Receipt, Alert) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.actionLoading = false;
        $scope.action = 'select';
        $scope.receipt = $scope.result;

        $scope.reasons = [
            {code: 'AC01', label: $translate('BANKS.AC01')},
            {code: 'AC04', label: $translate('BANKS.AC04')},
            {code: 'AC06', label: $translate('BANKS.AC06')},
            {code: 'AC13', label: $translate('BANKS.AC13')},
            {code: 'AG01', label: $translate('BANKS.AG01')},
            {code: 'AG02', label: $translate('BANKS.AG02')},
            {code: 'AM04', label: $translate('BANKS.AM04')},
            {code: 'AM05', label: $translate('BANKS.AM05')},
            {code: 'BE01', label: $translate('BANKS.BE01')},
            {code: 'BE05', label: $translate('BANKS.BE05')},
            {code: 'CNOR', label: $translate('BANKS.CNOR')},
            {code: 'DNOR', label: $translate('BANKS.DNOR')},
            {code: 'FF01', label: $translate('BANKS.FF01')},
            {code: 'MD01', label: $translate('BANKS.MD01')},
            {code: 'MD02', label: $translate('BANKS.MD02')},
            {code: 'MD07', label: $translate('BANKS.MD07')},
            {code: 'MS02', label: $translate('BANKS.MS02')},
            {code: 'MS03', label: $translate('BANKS.MS03')},
            {code: 'RC01', label: $translate('BANKS.RC01')},
            {code: 'RR01', label: $translate('BANKS.RR01')},
            {code: 'RR02', label: $translate('BANKS.RR02')},
            {code: 'RR03', label: $translate('BANKS.RR03')},
            {code: 'RR04', label: $translate('BANKS.RR04')},
            {code: 'SL01', label: $translate('BANKS.SL01')},
            {code: 'MAN1', label: $translate('BANKS.MAN1')},
            {code: 'CRC', label: $translate('BANKS.CREDIT_RETURNED_CUSTOMER')}
        ];

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.canDeleteReceipt = function (receipt) {
            return UserInfo.hasPerm('banks.delete_receipt');
        };

        $scope.canReturnReceipt = function () {
            return ((UserInfo.hasPerm('banks.return_receipts')) && (UserInfo.hasPerm('banks.change_receipt')) && ($scope.result.status != 'RTN'));
        };

        $scope.canPayReceipt = function () {
            return $scope.result.status == 'RTN';
        };

        $scope.deleteReceipt = function () {
            $scope.action = 'delete';
        };
        $scope.returnReceipt = function () {
            $scope.action = 'return';
        };
        $scope.payReceipt = function () {
            $scope.action = 'pay';
        };
        $scope.cancelDropdownAction = function () {
            $scope.action = 'select';
        };

        $scope.confirmDeleteReceipt = function () {
            $scope.actionLoading = true;
            Receipt.delete({id: $scope.receipt.iid}, $scope.receipt, function () {
                $scope.actionLoading = false;
                $scope.action = 'select';
                $rootScope.$broadcast('refreshInterface');
                $scope.$emit('refreshList')
            }, function (error) {
                Alert.createAlert('danger', error.data, true);
                $scope.actionLoading = false;
                $scope.action = 'select';
            });
        };

        $scope.confirmReturnReceipt = function () {
            $scope.actionLoading = true;
            Receipt.patch({id: $scope.receipt.iid}, {
                'status': 'RTN',
                'reason': $scope.receipt.reason,
                'date': $scope.receipt.return_date + 'T00:00'
            }, function (d) {
                $scope.actionLoading = false;
                $scope.action = 'select';
                $rootScope.$broadcast('refreshInterface');
                $scope.$emit('refreshList')
            }, function (error) {
                Alert.createAlert('danger', error.data, true);
                $scope.actionLoading = false;
                $scope.action = 'select';
            });
        };

        $scope.confirmPayReceipt = function () {
            $scope.actionLoading = true;
            Receipt.patch({id: $scope.receipt.id}, {'status': 'PAI'}, function (d) {
                $scope.actionLoading = false;
                $scope.action = 'select';
                $rootScope.$broadcast('refreshInterface');
            });
        };

    }
]

export { ReceiptActionsCtrl }
