const InvoiceSeries = ['$resource',
    function ($resource) {
        return $resource(API_URL + '/api/1.0/invoices-series/:id ', { id: '@id'}, {
            update: {
                method: 'PUT' // this method issues a PUT request
            },
            patch: {
                method: 'PATCH' // this method issues a PATCH request
            }
        });
    }
]

export { InvoiceSeries }
