const SingleProductRateCreateCtrl = ['$scope', '$filter', 'ProductRateAPI', 'UserInfo', 'PRODUCTS_VISIBILITY', 'RATES_INVOICING_MODE', 'Alert',
    function ($scope, $filter, ProductRateAPI, UserInfo, PRODUCTS_VISIBILITY, RATES_INVOICING_MODE, Alert) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

            /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.invoicing_mode = RATES_INVOICING_MODE;

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.resetForm = function(){
            $scope.create_rate = {invoicing_mode: 'NOR', visibility: PRODUCTS_VISIBILITY.VISIBLE, not_in_force: false};
        };

        $scope.saveCreateSingleProductRate = function () {
            if ($scope.company) {
                $scope.create_rate.company = $scope.company;
            }            
            ProductRateAPI.createProductRate($scope.create_rate).then(function (product_rate) {
                $scope.resetForm();
                $scope.createSingleProductRateForm.$setPristine();
                $scope.createSingleProductRateForm.$setUntouched();
                $scope.hideCreateRateForm();
                Alert.createAlert('success', $translate('PRODUCTS.PRODUCT_RATE_CREATED_SUCCESSFULLY'), true);
                $scope.$emit('refreshRates');
            }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
            });
        };

        $scope.cancelCreateSingleProductRate = function() {
            $scope.action = 'list';
            $scope.hideCreateRateForm();
            $scope.$emit('refreshRates');
        };

        $scope.canCreateRate = function () {
            return UserInfo.hasPerm('products.add_productrate');
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        $scope.resetForm();

    }
]

export { SingleProductRateCreateCtrl }
