const CreateInvoiceSeriesCtrl = ['$scope','$rootScope', 'InvoiceSeriesAPI', '$filter', 'Alert',
    function($scope, $rootScope, InvoiceSeriesAPI, $filter, Alert) {
        var $translate = $filter('translate');

        $scope.new_serie = {'counter': 0, 'invoice_number_len': 7, 'types_allowed': '1'}

        $scope.resetForm = function(){
            $scope.new_serie = {'counter': 0, 'invoice_number_len': 7, 'types-allowed': '1'}
        };

        $scope.createInvoiceSeries = function(invoice_serie) {
            InvoiceSeriesAPI.createInvoiceSeries(invoice_serie).then(function(data){
                $rootScope.$broadcast('refreshList')
                $scope.resetForm()
                Alert.createAlert('success', $translate('INVOICES.INVOICE_SERIES_CREATED_SUCCESSFULLY'), true);
            }).catch(function(data){
                Alert.createAlert('danger', $translate('INVOICES.INVOICE_SERIES_CREATED_FAIL'), true);
            });
        };
    }
]

export { CreateInvoiceSeriesCtrl }
