const ProviderInvoicesSyncCtrl = ['$scope', '$filter', '$window', 'UserInfo', 'Alert', 'Task', 'filtersUtils', 'INVOICES_CHANGEABLE_TYPES', 'INVOICES_TYPES', 'BrandsList',
    function ($scope, $filter, $window, UserInfo, Alert, Task, filtersUtils, INVOICES_CHANGEABLE_TYPES, INVOICES_TYPES, BrandsList) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.invoices_various = INVOICES_CHANGEABLE_TYPES;

        $scope.update_list = {
            'type': '',
            'company': '',
            'brand': '',
            'request_date': ''
        };

        $scope.brands = [];
        $scope.invoices_types = INVOICES_TYPES;

        $scope.filters = {
            invoice_type: "",
            company: ""
        }
        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        this.getJSONParams = function (elements) {
            var params = {};
            params.ids = filtersUtils.getItemsToDownloadToInt(elements);
            return params;
        };

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.getStyles = function (result) {
            var styles = '';
            if (result.status == 'PEN')
                styles += ' ' + 'info';
            if (result.status == 'DWN')
                styles += ' ' + 'warning';
            if (result.status == 'ERR')
                styles += ' ' + 'danger';
            if (result.status == 'EPR')
                styles += ' ' + 'danger';
            return styles;
        };

        $scope.syncInvoicesFromImporter = function () {
            var task = new Task();
            task.type = 'FISY';
            task.params = $scope.update_list;
            task.start()
                .then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('PROVIDERS.BULK_SYNC_F1_FROM_IMPORTER'), true);
                })
                .catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('PROVIDERS.BULK_SYNC_F1_FROM_IMPORTER_ERROR'), true);
                });
        };

        $scope.downloadInvoicesFromImporter = function () {
            var task = new Task();
            task.type = 'FIDW';
            task.params = {};
            task.start()
                .then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('PROVIDERS.BULK_DOWNLOAD_F1_FROM_IMPORTER'), true);
                })
                .catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('PROVIDERS.BULK_DOWNLOAD_F1_FROM_IMPORTER_ERROR'), true);
                });
        };

        $scope.processDownloadedInvoices = function () {
            var task = new Task();
            task.type = 'FPRO';
            task.params = $scope.update_list;
            task.start()
                .then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('PROVIDERS.BULK_PROCESS_F1'), true);
                })
                .catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('PROVIDERS.BULK_PROCESS_F1_ERROR'), true);
                });
        };

        $scope.canCreateProviderInvoice = function () {
            return UserInfo.hasPerm('providers.add_providerinvoice');
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/

        BrandsList.all().then(function (response) {
            if(response)
                $scope.brands = [{
                    'code': 'null',
                    'name': $translate('BRANDS.NONE')
                }].concat(response)
        });
    }
]

export { ProviderInvoicesSyncCtrl }
