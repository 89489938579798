const CreateInvoiceCtrl = ['$scope', 'Invoice', '$filter', 'Alert', '$location',
    function ($scope, Invoice, $filter, Alert, $location) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.saving = false;

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.saveInvoice = function(){
            $scope.saving = true;
            var invoice = new Invoice();
            invoice.start_date = $filter('date')($scope.start_date, 'yyyy-MM-dd');
            invoice.finish_date = $filter('date')($scope.finish_date, 'yyyy-MM-dd');
            invoice.contract = $scope.selected_contract.id;

            invoice.$save(
                function(success){
                    $scope.saving = false;
                    Alert.createAlert('success', $translate('INVOICES.CREATE_MANUAL_INVOICE'), true);
                    $location.path('/invoices/' + success.id);
                }, function(error){
                    $scope.saving = false
                    if (typeof error.data === 'string')
                        $scope.error_message = error.data;
                    else {
                        for(var k in error.data){
                            $scope.error_message += error.data[k] + ' ';
                        }
                    }

                    Alert.createAlert('danger', $scope.error_message, true);
                }
            );

        };
    }
]

export { CreateInvoiceCtrl }
