const Companies = ['$q', '$http',
    function ($q, $http) {

        let url = API_URL + '/api/1.0/companies/';

        function listCompanies(){
            let deferred = $q.defer();

            $http.get(url)
                .then(function(response) {
                    deferred.resolve(response.data);
                })
                .catch(function (response) {
                    deferred.reject(response.data);
                });

            return deferred.promise;
        }

        return {
            listCompanies: listCompanies
        };
    }
]

export {Companies}
